import { Link, Typography } from '@mui/material';
import { Property } from 'api/v3/aas_core_meta/types';
import { messages } from 'i18n/localization';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { isValidUrl } from '../../../../util/UrlUtil';

type PropertyComponentProps = {
    readonly property: Property;
};

export function PropertyComponent(props: PropertyComponentProps) {
    const { property } = props;
    if (property && property.value && (property.value === 'true' || property.value === 'false')) {
        return (
            <Typography data-testid="property-content">
                <FormattedMessage {...messages.mnestix.boolean[property.value]} />
            </Typography>
        );
    } else {
        return (
            <Typography data-testid="property-content">
                { isValidUrl(property.value) ?
                    <Link component={RouterLink} to={property.value!} target="_blank" rel="noopener noreferrer">
                        {property.value}
                        <OpenInNew fontSize="small" sx={{ verticalAlign: 'middle', ml:1 }}/>
                    </Link> :
                    property.value?.toString() || <FormattedMessage {...messages.mnestix.notAvailable} />
                }
            </Typography>
        );
    }
}
