import { Link, Typography } from '@mui/material';
import { getTranslationText } from 'util/SubmodelResolverUtil';
import { useIntl } from 'react-intl';
import { MultiLanguageProperty } from 'api/v3/aas_core_meta/types';
import { isValidUrl } from '../../../../util/UrlUtil';
import { Link as RouterLink } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';

type MultiLanguagePropertyComponentProps = {
    readonly mLangProp: MultiLanguageProperty;
};

export function MultiLanguagePropertyComponent(props: MultiLanguagePropertyComponentProps) {
    const { mLangProp } = props;
    const intl = useIntl();
    const value = getTranslationText(mLangProp, intl);

    if (isValidUrl(value)) {
        return (
            <Typography data-testid="mlproperty-content">
                <Link component={RouterLink} to={value!} target="_blank" rel="noopener noreferrer" >
                    {value}
                    <OpenInNew fontSize="small" sx={{ verticalAlign: 'middle', ml:1 }}/>
                </Link>
            </Typography>
        )
    }
    return <Typography data-testid="mlproperty-content">{value || '-'}</Typography>;
}