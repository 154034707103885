import { Submodel } from 'api/v3/aas_core_meta/types';
import { CoffeeConsumptionVisualizations } from './CoffeeConsumptionVisualizations';

type ReferenceCounterDetailProps = {
    readonly submodel: Submodel;
};

export function CoffeeConsumptionDetail(props: ReferenceCounterDetailProps) {
    return (
        <>
            <CoffeeConsumptionVisualizations submodel={props.submodel} />
        </>
    );
}
