import { ArrowForward } from '@mui/icons-material';
import { Alert, Box, Button, Paper, Switch, TextField, Typography } from '@mui/material';
import { XitasoLogo, XitasoLogoVariant } from '../basics/XitasoLogo';

/**
 * This view is meant as a development "playground".
 * Here you can showcase different components without needing to mess with the "real" application.
 * This is meant as an intermediate view for initial development and can be deleted afterwards.
 */

export function Showcase() {
    return (
        <Box sx={{ p: 2, maxWidth: '1125px', margin: '0 auto' }}>
            <Box sx={{ my: 2 }} display="flex">
                <Box sx={{ bgcolor: 'primary.main', p: 2 }}>
                    <XitasoLogo variant={XitasoLogoVariant.Light} width="200px" />
                </Box>
                <Box sx={{ p: 2 }}>
                    <XitasoLogo variant={XitasoLogoVariant.Dark} width="200px" />
                </Box>
            </Box>
            <Typography sx={{ p: 2 }} variant="h1">
                Heading 1
            </Typography>
            <Typography sx={{ p: 2 }} variant="h2">
                Heading 2
            </Typography>
            <Typography sx={{ p: 2 }} variant="h3">
                Heading 3
            </Typography>
            <Typography sx={{ p: 2 }} variant="h4">
                Heading 4
            </Typography>
            <Typography sx={{ p: 2 }} variant="h5">
                Heading 5
            </Typography>
            <Typography sx={{ p: 2 }} variant="h6">
                Heading 6
            </Typography>
            <Typography sx={{ p: 2 }} variant="body1">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </Typography>
            <Typography sx={{ p: 2 }} variant="body2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </Typography>
            <Box sx={{ p: 2, pt: 6 }}>
                <TextField label="TextField" />
                <Switch defaultChecked />
                <Switch />
            </Box>
            <Button sx={{ m: 2 }} variant="contained" startIcon={<ArrowForward />}>
                button
            </Button>
            <Button sx={{ m: 2 }} variant="outlined" startIcon={<ArrowForward />}>
                button
            </Button>
            <Button sx={{ m: 2 }} variant="contained" color="secondary" startIcon={<ArrowForward />}>
                secondary
            </Button>
            <Button sx={{ m: 2 }} variant="outlined" color="secondary" startIcon={<ArrowForward />}>
                secondary
            </Button>
            <Paper sx={{ p: 2, m: 2, mt: 6 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    This is an error alert — check it out!
                </Alert>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    This is a warning alert — check it out!
                </Alert>
                <Alert severity="info" sx={{ mb: 2 }}>
                    This is an info alert — check it out!
                </Alert>
                <Alert severity="success">This is a success alert — check it out!</Alert>
            </Paper>
        </Box>
    );
}
