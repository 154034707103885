/**
 * Provide an implementation of the Asset Administration Shell (AAS) V3.0.
 *
 * @remarks
 * The presented version of the Metamodel is related to the work of
 * aas-core-works, which can be found here: https://github.com/aas-core-works.
 *
 * The presented content is neither related to the IDTA nor
 * Plattform Industrie 4.0 and does not represent an official publication.
 *
 * We diverge from the book in the following points.
 *
 * We did not implement the following constraints as they are too general and can not
 * be formalized as part of the core library, but affects external components such as
 * AAS registry or AAS server:
 *
 * * Constraint AASd-022
 *
 * We did not implement the following constraints since they depend on registry and
 * de-referencing of {@link Reference} objects:
 *
 * * Constraint AASd-006
 * * Constraint AASd-007
 * * Constraint AASc-3a-003
 *
 * Some constraints are not enforceable as they depend on the wider context
 * such as language understanding, so we could not formalize them:
 *
 * * Constraint AASd-012: This constraint requires that the texts inside
 *   `Multi_language_property` shall have the same meanings in the separate languages.
 *   This cannot be tested.
 * * Constraint AASd-116: In the book, Constraint AASd-116 imposes a
 *   case-insensitive equality against `globalAssetId`. This is culturally-dependent,
 *   and depends on the system settings. For example, the case-folding
 *   for the letters "i" and "I" is different in Turkish from English.
 *
 *   We implement the constraint as case-sensitive instead to allow for interoperability
 *   across different culture settings.
 *
 * Furthermore, we diverge from the book in the following points regarding
 * the enumerations. We have to implement subsets of enumerations as sets as common
 * programming languages do not support inheritance of enumerations. The relationship
 * between the properties and the sets is defined through invariants. This causes
 * the following divergences:
 *
 * * We decided therefore to remove the enumeration `DataTypeDefRDF`
 *   and keep only {@link DataTypeDefXsd} as enumeration. Otherwise, we would have
 *   to write redundant invariants all over the meta-model because `DataTypeDefRDF`
 *   is actually never used in any type definition.
 * * The enumeration {@link AasSubmodelElements} is used in two different contexts.
 *   One context is the definition of key types in a reference. Another context is
 *   the definition of element types in a {@link SubmodelElementList}.
 *
 *   To avoid confusion, we introduce two separate enumerations for the separate contexts.
 *   Firstly, a set of {@link KeyTypes}, {@link constants!AAS_SUBMODEL_ELEMENTS_AS_KEYS} to
 *   represent the first context (key type in a reference).
 *   Secondly, the enumeration {@link AasSubmodelElements} is kept as designator
 *   for {@link SubmodelElementList.typeValueListElement}.
 * * The specification introduces several types of `Lang_string_set`.
 *   These types differ between the allowed length of their text inside the singular
 *   `Lang_string` objects. Since the native representation of `Lang_string_set` as
 *   `List` of `Lang_string` is required by specification, it is impossible to
 *   introduce separate `Lang_string_set` types. Therefore, the distinction is drawn here
 *   between the `Lang_string` types.
 *
 *   `DefinitionTypeIEC61360` is represented as a
 *   `List` of {@link LangStringDefinitionTypeIec61360}
 *
 *   `MultiLanguageNameType` is represented as a
 *   `List` of {@link LangStringNameType}
 *
 *   `PreferredNameTypeIEC61360` is represented as a
 *   `List` of {@link LangStringPreferredNameTypeIec61360}
 *
 *   `ShortNameTypeIEC61360` is represented as a
 *   `List` of {@link LangStringShortNameTypeIec61360}
 *
 *   `MultiLanguageTextType` is represented as a
 *   `List` of {@link LangStringTextType}
 *
 *   Furthermore, since `Lang_string` is not used anywhere, we rename it to
 *   {@link IAbstractLangString}.
 *
 * Concerning the data specifications, we embed them within
 * {@link IHasDataSpecification} instead of referencing them *via* an external reference.
 * The working group decided to change the rules for serialization *after* the book was
 * published. The data specifications are critical in applications, but there is no
 * possibility to access them through a data channel as they are not part of
 * an environment.
 */

// This code has been automatically generated by aas-core-codegen.
// Do NOT edit or append.

/**
 * Represent the most general class of an AAS model.
 */
export abstract class Class {
  /**
   * Iterate over all the instances referenced from this one.
   */
  abstract descendOnce(): IterableIterator<Class>;

  /**
   * Iterate recursively over all the instances referenced from this one.
   */
  abstract descend(): IterableIterator<Class>;

  /**
   * Dispatch the `visitor` on this instance.
   *
   * @param visitor - to be dispatched
   */
  abstract accept(visitor: AbstractVisitor): void;

  /**
   * Dispatch the `visitor` on this instance with `context`.
   *
   * @param visitor - to be dispatched
   * @param context - of the visitation
   * @typeParam ContextT - type of the visitation context
   */
  abstract acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ): void;

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to be dispatched
   * @return this instance transformed
   * @typeParam T - type of the transformation result
   */
  abstract transform<T>(transformer: AbstractTransformer<T>): T;

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to be dispatched
   * @param context - of the transformation
   * @return this instance transformed
   * @typeParam T - type of the transformation result
   */
  abstract transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T;
}

/**
 * Element that can have a semantic definition plus some supplemental semantic
 * definitions.
 *
 * Constraint `AASd-118`:
 * If there are ID {@link supplementalSemanticIds} defined
 * then there shall be also a main semantic ID {@link semanticId}.
 */
export interface IHasSemantics extends Class {
  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference>;
}

/**
 * Single extension of an element.
 */
export class Extension extends Class implements IHasSemantics {
  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Name of the extension.
   *
   * Constraint `AASd-077`:
   * The name of an extension (Extension/name) within {@link IHasExtensions} needs
   * to be unique.
   */
  name: string;

  /**
   * Type of the value of the extension.
   *
   * @remarks
   * Default: {@link DataTypeDefXsd.String}
   */
  valueType: DataTypeDefXsd | null;

  /**
   * Value of the extension
   */
  value: string | null;

  /**
   * Reference to an element the extension refers to.
   */
  refersTo: Array<Reference> | null;

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link refersTo} if it is set, or yield nothing.
   */
  *overRefersToOrEmpty(): IterableIterator<Reference> {
    if (this.refersTo !== null) {
      yield* this.refersTo;
    }
    return;
  }

  /**
   * @returns {@link valueType} if set or the default value otherwise.
   */
  valueTypeOrDefault(): DataTypeDefXsd {
    return this.valueType !== null ? this.valueType : DataTypeDefXsd.String;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.refersTo !== null) {
      yield* this.refersTo;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const anItem of this.supplementalSemanticIds) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.refersTo !== null) {
      for (const anotherItem of this.refersTo) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitExtension(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitExtensionWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformExtension(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformExtensionWithContext(this, context);
  }

  constructor(
    name: string,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    valueType: DataTypeDefXsd | null = null,
    value: string | null = null,
    refersTo: Array<Reference> | null = null
  ) {
    super();
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.name = name;
    this.valueType = valueType;
    this.value = value;
    this.refersTo = refersTo;
  }
}

/**
 * Element that can be extended by proprietary extensions.
 *
 * @remarks
 * **Note**:
 * Extensions are proprietary, i.e. they do not support global interoperability.
 */
export interface IHasExtensions extends Class {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  overExtensionsOrEmpty(): IterableIterator<Extension>;
}

/**
 * An element that is referable by its {@link idShort}.
 *
 * @remarks
 * This ID is not globally unique.
 * This ID is unique within the name space of the element.
 *
 * Constraint `AASd-022`:
 * {@link idShort} of non-identifiable referables
 * within the same name space shall be unique (case-sensitive).
 */
export interface IReferable extends IHasExtensions {
  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  overDisplayNameOrEmpty(): IterableIterator<LangStringNameType>;

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  overDescriptionOrEmpty(): IterableIterator<LangStringTextType>;
}

/**
 * An element that has a globally unique identifier.
 */
export interface IIdentifiable extends IReferable {
  /**
   * Administrative information of an identifiable element.
   *
   * @remarks
   * **Note**:
   * Some of the administrative information like the version number might need to
   * be part of the identification.
   */
  administration: AdministrativeInformation | null;

  /**
   * The globally unique identification of the element.
   */
  id: string;
}

/**
 * Enumeration for denoting whether an element is a template or an instance.
 */
export enum ModellingKind {
  /**
   * Specification of the common features of a structured element in sufficient detail
   * that such a instance can be instantiated using it
   */
  Template = 0,
  /**
   * Concrete, clearly identifiable element instance. Its creation and validation
   * may be guided by a corresponding element template.
   */
  Instance
}

/**
 * Iterate over the literals of {@link ModellingKind}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overModellingKind(): IterableIterator<ModellingKind> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <ModellingKind>0; // Template
  yield <ModellingKind>1; // Instance
}

/**
 * An element with a kind is an element that can either represent a template or an
 * instance.
 *
 * @remarks
 * Default for an element is that it is representing an instance.
 */
export interface IHasKind extends Class {
  /**
   * Kind of the element: either type or instance.
   *
   * @remarks
   * Default: {@link ModellingKind.Instance}
   */
  kind: ModellingKind | null;

  kindOrDefault(): ModellingKind;
}

/**
 * Element that can be extended by using data specification templates.
 *
 * @remarks
 * A data specification template defines a named set of additional attributes an
 * element may or shall have. The data specifications used are explicitly specified
 * with their global ID.
 */
export interface IHasDataSpecification extends Class {
  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification>;
}

/**
 * Administrative meta-information for an element like version
 * information.
 *
 * Constraint `AASd-005`:
 * If {@link AdministrativeInformation.version} is not specified then also {@link AdministrativeInformation.revision} shall be
 * unspecified. This means, a revision requires a version. If there is no version
 * there is no revision neither. Revision is optional.
 */
export class AdministrativeInformation extends Class implements IHasDataSpecification {
  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Version of the element.
   */
  version: string | null;

  /**
   * Revision of the element.
   */
  revision: string | null;

  /**
   * The subject ID of the subject responsible for making the element.
   */
  creator: Reference | null;

  /**
   * Identifier of the template that guided the creation of the element.
   *
   * @remarks
   * **Note**:
   * In case of a submodel the {@link templateId} is the identifier
   * of the submodel template ID that guided the creation of the submodel
   *
   * **Note**:
   * The {@link templateId} is not relevant for validation in Submodels.
   * For validation the {@link Submodel.semanticId} shall be used.
   *
   * **Note**:
   * Usage of {@link templateId} is not restricted to submodel instances. So also
   * the creation of submodel templates can be guided by another submodel template.
   */
  templateId: string | null;

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.creator !== null) {
      yield this.creator;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.embeddedDataSpecifications !== null) {
      for (const anItem of this.embeddedDataSpecifications) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.creator !== null) {
      yield this.creator;

      yield* this.creator.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitAdministrativeInformation(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitAdministrativeInformationWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformAdministrativeInformation(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformAdministrativeInformationWithContext(this, context);
  }

  constructor(
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    version: string | null = null,
    revision: string | null = null,
    creator: Reference | null = null,
    templateId: string | null = null
  ) {
    super();
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.version = version;
    this.revision = revision;
    this.creator = creator;
    this.templateId = templateId;
  }
}

/**
 * The value of a qualifiable element may be further qualified by one or more
 * qualifiers.
 *
 * Constraint `AASd-119`:
 * If any {@link Qualifier.kind} value of {@link qualifiers} is
 * equal to {@link QualifierKind.TemplateQualifier} and the qualified element
 * inherits from {@link IHasKind} then the qualified element shall be of
 * kind Template ({@link IHasKind.kind} = {@link ModellingKind.Template}).
 *
 * **Note**:
 * This constraint is checked at {@link Submodel}.
 */
export interface IQualifiable extends Class {
  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  overQualifiersOrEmpty(): IterableIterator<Qualifier>;
}

/**
 * Enumeration for kinds of qualifiers.
 *
 * @remarks
 * **Note**:
 * This element is experimental and therefore may be subject to change or may be
 * removed completely in future versions of the meta-model.
 */
export enum QualifierKind {
  /**
   * qualifies the value of the element and can change during run-time.
   *
   * @remarks
   *
   * Value qualifiers are only applicable to elements with kind
   * {@link ModellingKind.Instance}.
   */
  ValueQualifier = 0,
  /**
   * qualifies the semantic definition the element is referring to
   * ({@link IHasSemantics.semanticId})
   */
  ConceptQualifier,
  /**
   * qualifies the elements within a specific submodel on concept level.
   *
   * @remarks
   *
   * Template qualifiers are only applicable to elements with kind
   * {@link ModellingKind.Template}.
   */
  TemplateQualifier
}

/**
 * Iterate over the literals of {@link QualifierKind}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overQualifierKind(): IterableIterator<QualifierKind> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <QualifierKind>0; // ValueQualifier
  yield <QualifierKind>1; // ConceptQualifier
  yield <QualifierKind>2; // TemplateQualifier
}

/**
 * A qualifier is a type-value-pair that makes additional statements w.r.t. the value
 * of the element.
 *
 * Constraint `AASd-006`:
 * If both the {@link Qualifier.value} and the {@link Qualifier.valueId} of
 * a {@link Qualifier} are present then the {@link Qualifier.value} needs
 * to be identical to the value of the referenced coded value
 * in {@link Qualifier.valueId}.
 *
 * Constraint `AASd-020`:
 * The value of {@link Qualifier.value} shall be consistent to the data type as
 * defined in {@link Qualifier.valueType}.
 */
export class Qualifier extends Class implements IHasSemantics {
  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * The qualifier kind describes the kind of the qualifier that is applied to the
   * element.
   *
   * @remarks
   * Default: {@link QualifierKind.ConceptQualifier}
   */
  kind: QualifierKind | null;

  /**
   * The qualifier *type* describes the type of the qualifier that is applied to
   * the element.
   */
  type: string;

  /**
   * Data type of the qualifier value.
   */
  valueType: DataTypeDefXsd;

  /**
   * The qualifier value is the value of the qualifier.
   */
  value: string | null;

  /**
   * Reference to the global unique ID of a coded value.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  valueId: Reference | null;

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * @returns {@link kind} if set or the default value otherwise.
   */
  kindOrDefault(): QualifierKind {
    return this.kind !== null ? this.kind : QualifierKind.ConceptQualifier;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.valueId !== null) {
      yield this.valueId;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const anItem of this.supplementalSemanticIds) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.valueId !== null) {
      yield this.valueId;

      yield* this.valueId.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitQualifier(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitQualifierWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformQualifier(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformQualifierWithContext(this, context);
  }

  constructor(
    type: string,
    valueType: DataTypeDefXsd,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    kind: QualifierKind | null = null,
    value: string | null = null,
    valueId: Reference | null = null
  ) {
    super();
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.type = type;
    this.valueType = valueType;
    this.kind = kind;
    this.value = value;
    this.valueId = valueId;
  }
}

/**
 * An asset administration shell.
 */
export class AssetAdministrationShell
  extends Class
  implements IIdentifiable, IHasDataSpecification
{
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Administrative information of an identifiable element.
   *
   * @remarks
   * **Note**:
   * Some of the administrative information like the version number might need to
   * be part of the identification.
   */
  administration: AdministrativeInformation | null;

  /**
   * The globally unique identification of the element.
   */
  id: string;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * The reference to the AAS the AAS was derived from.
   */
  derivedFrom: Reference | null;

  /**
   * Meta-information about the asset the AAS is representing.
   */
  assetInformation: AssetInformation;

  /**
   * References to submodels of the AAS.
   *
   * @remarks
   * A submodel is a description of an aspect of the asset the AAS is representing.
   *
   * The asset of an AAS is typically described by one or more submodels.
   *
   * Temporarily no submodel might be assigned to the AAS.
   */
  submodels: Array<Reference> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link submodels} if it is set, or yield nothing.
   */
  *overSubmodelsOrEmpty(): IterableIterator<Reference> {
    if (this.submodels !== null) {
      yield* this.submodels;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.administration !== null) {
      yield this.administration;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.derivedFrom !== null) {
      yield this.derivedFrom;
    }

    yield this.assetInformation;

    if (this.submodels !== null) {
      yield* this.submodels;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.administration !== null) {
      yield this.administration;

      yield* this.administration.descend();
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.derivedFrom !== null) {
      yield this.derivedFrom;

      yield* this.derivedFrom.descend();
    }

    yield this.assetInformation;

    yield* this.assetInformation.descend();

    if (this.submodels !== null) {
      for (const yetYetYetAnotherItem of this.submodels) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitAssetAdministrationShell(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitAssetAdministrationShellWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformAssetAdministrationShell(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformAssetAdministrationShellWithContext(this, context);
  }

  constructor(
    id: string,
    assetInformation: AssetInformation,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    administration: AdministrativeInformation | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    derivedFrom: Reference | null = null,
    submodels: Array<Reference> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.id = id;
    this.administration = administration;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.derivedFrom = derivedFrom;
    this.assetInformation = assetInformation;
    this.submodels = submodels;
  }
}

/**
 * In {@link AssetInformation} identifying meta data of the asset that is
 * represented by an AAS is defined.
 *
 * @remarks
 * The asset may either represent an asset type or an asset instance.
 *
 * The asset has a globally unique identifier plus – if needed – additional domain
 * specific (proprietary) identifiers. However, to support the corner case of very
 * first phase of lifecycle where a stabilised/constant_set global asset identifier
 * does not already exist, the corresponding attribute {@link AssetInformation.globalAssetId} is
 * optional.
 *
 * Constraint `AASd-116`:
 * `globalAssetId` is a reserved key. If used as value for
 * {@link SpecificAssetId.name} then {@link SpecificAssetId.value} shall be
 * identical to {@link AssetInformation.globalAssetId}.
 *
 * **Note**:
 * Constraint AASd-116 is important to enable a generic search across
 * global and specific asset IDs.
 *
 * **Note**:
 * In the book, Constraint AASd-116 imposes a
 * case-insensitive equality against `globalAssetId`. This is
 * culturally-dependent, and depends on the system settings.
 * For example, the case-folding for the letters "i" and "I" is
 * different in Turkish from English.
 *
 * We implement the constraint as case-sensitive instead to allow
 * for interoperability across different culture settings.
 *
 * Constraint `AASd-131`:
 * For {@link AssetInformation} either the {@link AssetInformation.globalAssetId} shall be
 * defined or at least one item in {@link AssetInformation.specificAssetIds}.
 */
export class AssetInformation extends Class {
  /**
   * Denotes whether the Asset is of kind {@link AssetKind.Type} or
   * {@link AssetKind.Instance}.
   */
  assetKind: AssetKind;

  /**
   * Global identifier of the asset the AAS is representing.
   *
   * @remarks
   * This attribute is required as soon as the AAS is exchanged via partners in the life
   * cycle of the asset. In a first phase of the life cycle the asset might not yet have
   * a global ID but already an internal identifier. The internal identifier would be
   * modelled via {@link specificAssetIds}.
   *
   * **Note**:
   * This is a global reference.
   */
  globalAssetId: string | null;

  /**
   * Additional domain-specific, typically proprietary identifier for the asset like
   * e.g., serial number etc.
   */
  specificAssetIds: Array<SpecificAssetId> | null;

  /**
   * In case {@link assetKind} is applicable the {@link assetType} is the asset ID
   * of the type asset of the asset under consideration
   * as identified by {@link globalAssetId}.
   *
   * @remarks
   * **Note**:
   * In case {@link assetKind} is "Instance" than the {@link assetType} denotes
   * which "Type" the asset is of. But it is also possible
   * to have an {@link assetType} of an asset of kind "Type".
   */
  assetType: string | null;

  /**
   * Thumbnail of the asset represented by the Asset Administration Shell.
   *
   * @remarks
   * Used as default.
   */
  defaultThumbnail: Resource | null;

  /**
   * Yield from {@link specificAssetIds} if it is set, or yield nothing.
   */
  *overSpecificAssetIdsOrEmpty(): IterableIterator<SpecificAssetId> {
    if (this.specificAssetIds !== null) {
      yield* this.specificAssetIds;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.specificAssetIds !== null) {
      yield* this.specificAssetIds;
    }

    if (this.defaultThumbnail !== null) {
      yield this.defaultThumbnail;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.specificAssetIds !== null) {
      for (const anItem of this.specificAssetIds) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.defaultThumbnail !== null) {
      yield this.defaultThumbnail;

      yield* this.defaultThumbnail.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitAssetInformation(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitAssetInformationWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformAssetInformation(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformAssetInformationWithContext(this, context);
  }

  constructor(
    assetKind: AssetKind,
    globalAssetId: string | null = null,
    specificAssetIds: Array<SpecificAssetId> | null = null,
    assetType: string | null = null,
    defaultThumbnail: Resource | null = null
  ) {
    super();
    this.assetKind = assetKind;
    this.globalAssetId = globalAssetId;
    this.specificAssetIds = specificAssetIds;
    this.assetType = assetType;
    this.defaultThumbnail = defaultThumbnail;
  }
}

/**
 * Resource represents an address to a file (a locator). The value is an URI that
 * can represent an absolute or relative path
 */
export class Resource extends Class {
  /**
   * Path and name of the resource (with file extension).
   *
   * @remarks
   * The path can be absolute or relative.
   */
  path: string;

  /**
   * Content type of the content of the file.
   *
   * @remarks
   * The content type states which file extensions the file can have.
   */
  contentType: string | null;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitResource(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitResourceWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformResource(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformResourceWithContext(this, context);
  }

  constructor(path: string, contentType: string | null = null) {
    super();
    this.path = path;
    this.contentType = contentType;
  }
}

/**
 * Enumeration for denoting whether an asset is a type asset or an instance asset.
 */
export enum AssetKind {
  /**
   * Type asset
   */
  Type = 0,
  /**
   * Instance asset
   */
  Instance,
  /**
   * Neither a type asset nor an instance asset
   */
  NotApplicable
}

/**
 * Iterate over the literals of {@link AssetKind}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overAssetKind(): IterableIterator<AssetKind> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <AssetKind>0; // Type
  yield <AssetKind>1; // Instance
  yield <AssetKind>2; // NotApplicable
}

/**
 * A specific asset ID describes a generic supplementary identifying attribute of the
 * asset.
 *
 * @remarks
 * The specific asset ID is not necessarily globally unique.
 *
 * Constraint `AASd-133`:
 * {@link SpecificAssetId.externalSubjectId} shall be an external reference,
 * i.e. {@link Reference.type} = {@link ReferenceTypes.ExternalReference}.
 */
export class SpecificAssetId extends Class implements IHasSemantics {
  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Name of the identifier
   */
  name: string;

  /**
   * The value of the specific asset identifier with the corresponding name.
   */
  value: string;

  /**
   * The (external) subject the key belongs to or has meaning to.
   *
   * @remarks
   * **Note**:
   * This is a global reference.
   */
  externalSubjectId: Reference | null;

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.externalSubjectId !== null) {
      yield this.externalSubjectId;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const anItem of this.supplementalSemanticIds) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.externalSubjectId !== null) {
      yield this.externalSubjectId;

      yield* this.externalSubjectId.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitSpecificAssetId(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitSpecificAssetIdWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformSpecificAssetId(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformSpecificAssetIdWithContext(this, context);
  }

  constructor(
    name: string,
    value: string,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    externalSubjectId: Reference | null = null
  ) {
    super();
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.name = name;
    this.value = value;
    this.externalSubjectId = externalSubjectId;
  }
}

/**
 * A submodel defines a specific aspect of the asset represented by the AAS.
 *
 * @remarks
 * A submodel is used to structure the digital representation and technical
 * functionality of an Administration Shell into distinguishable parts. Each submodel
 * refers to a well-defined domain or subject matter. Submodels can become
 * standardized and, thus, become submodels templates.
 */
export class Submodel
  extends Class
  implements
    IIdentifiable,
    IHasKind,
    IHasSemantics,
    IQualifiable,
    IHasDataSpecification
{
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Administrative information of an identifiable element.
   *
   * @remarks
   * **Note**:
   * Some of the administrative information like the version number might need to
   * be part of the identification.
   */
  administration: AdministrativeInformation | null;

  /**
   * The globally unique identification of the element.
   */
  id: string;

  /**
   * Kind of the element: either type or instance.
   *
   * @remarks
   * Default: {@link ModellingKind.Instance}
   */
  kind: ModellingKind | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * A submodel consists of zero or more submodel elements.
   */
  submodelElements: Array<ISubmodelElement> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link submodelElements} if it is set, or yield nothing.
   */
  *overSubmodelElementsOrEmpty(): IterableIterator<ISubmodelElement> {
    if (this.submodelElements !== null) {
      yield* this.submodelElements;
    }
    return;
  }

  /**
   * @returns {@link kind} if set or the default value otherwise.
   */
  kindOrDefault(): ModellingKind {
    return this.kind !== null ? this.kind : ModellingKind.Instance;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.administration !== null) {
      yield this.administration;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.submodelElements !== null) {
      yield* this.submodelElements;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.administration !== null) {
      yield this.administration;

      yield* this.administration.descend();
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.submodelElements !== null) {
      for (const yetYetYetYetYetAnotherItem of this.submodelElements) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitSubmodel(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitSubmodelWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformSubmodel(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformSubmodelWithContext(this, context);
  }

  constructor(
    id: string,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    administration: AdministrativeInformation | null = null,
    kind: ModellingKind | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    submodelElements: Array<ISubmodelElement> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.id = id;
    this.administration = administration;
    this.kind = kind;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.submodelElements = submodelElements;
  }
}

/**
 * A submodel element is an element suitable for the description and differentiation of
 * assets.
 *
 * @remarks
 * It is recommended to add a {@link IHasSemantics.semanticId} to a submodel element.
 *
 * Constraint `AASd-129`:
 * If any {@link Qualifier.kind} value of {@link qualifiers} (attribute qualifier
 * inherited via Qualifiable) is equal to {@link QualifierKind.TemplateQualifier}
 * then the submodel element shall be part of a submodel template, i.e.
 * a Submodel with {@link Submodel.kind} (attribute kind inherited via
 * {@link IHasKind}) value is equal to {@link ModellingKind.Template}.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubmodelElement
  extends IReferable,
    IHasSemantics,
    IQualifiable,
    IHasDataSpecification {
  // Intentionally empty.
}

/**
 * A relationship element is used to define a relationship between two elements
 * being either referable (model reference) or external (global reference).
 */
export interface IRelationshipElement extends ISubmodelElement {
  /**
   * Reference to the first element in the relationship taking the role of the subject.
   */
  first: Reference;

  /**
   * Reference to the second element in the relationship taking the role of the object.
   */
  second: Reference;
}

/**
 * A relationship element is used to define a relationship between two elements
 * being either referable (model reference) or external (global reference).
 */
export class RelationshipElement extends Class implements IRelationshipElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Reference to the first element in the relationship taking the role of the subject.
   */
  first: Reference;

  /**
   * Reference to the second element in the relationship taking the role of the object.
   */
  second: Reference;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    yield this.first;

    yield this.second;
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    yield this.first;

    yield* this.first.descend();

    yield this.second;

    yield* this.second.descend();
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitRelationshipElement(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitRelationshipElementWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformRelationshipElement(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformRelationshipElementWithContext(this, context);
  }

  constructor(
    first: Reference,
    second: Reference,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.first = first;
    this.second = second;
  }
}

/**
 * Enumeration of all possible elements of a {@link SubmodelElementList}.
 */
export enum AasSubmodelElements {
  AnnotatedRelationshipElement = 0,
  BasicEventElement,
  Blob,
  Capability,
  DataElement,
  Entity,
  EventElement,
  File,
  MultiLanguageProperty,
  Operation,
  Property,
  Range,
  ReferenceElement,
  RelationshipElement,
  SubmodelElement,
  SubmodelElementList,
  SubmodelElementCollection
}

/**
 * Iterate over the literals of {@link AasSubmodelElements}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overAasSubmodelElements(): IterableIterator<AasSubmodelElements> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <AasSubmodelElements>0; // AnnotatedRelationshipElement
  yield <AasSubmodelElements>1; // BasicEventElement
  yield <AasSubmodelElements>2; // Blob
  yield <AasSubmodelElements>3; // Capability
  yield <AasSubmodelElements>4; // DataElement
  yield <AasSubmodelElements>5; // Entity
  yield <AasSubmodelElements>6; // EventElement
  yield <AasSubmodelElements>7; // File
  yield <AasSubmodelElements>8; // MultiLanguageProperty
  yield <AasSubmodelElements>9; // Operation
  yield <AasSubmodelElements>10; // Property
  yield <AasSubmodelElements>11; // Range
  yield <AasSubmodelElements>12; // ReferenceElement
  yield <AasSubmodelElements>13; // RelationshipElement
  yield <AasSubmodelElements>14; // SubmodelElement
  yield <AasSubmodelElements>15; // SubmodelElementList
  yield <AasSubmodelElements>16; // SubmodelElementCollection
}

/**
 * A submodel element list is an ordered list of submodel elements.
 *
 * @remarks
 * The numbering starts with zero (0).
 *
 * Constraint `AASd-107`:
 * If a first level child element in a {@link SubmodelElementList} has
 * a {@link IHasSemantics.semanticId} it
 * shall be identical to {@link SubmodelElementList.semanticIdListElement}.
 *
 * Constraint `AASd-114`:
 * If two first level child elements in a {@link SubmodelElementList} have
 * a {@link IHasSemantics.semanticId} then they shall be identical.
 *
 * Constraint `AASd-115`:
 * If a first level child element in a {@link SubmodelElementList} does not
 * specify a {@link IHasSemantics.semanticId} then the value is assumed to be
 * identical to {@link SubmodelElementList.semanticIdListElement}.
 *
 * Constraint `AASd-108`:
 * All first level child elements in a {@link SubmodelElementList} shall have
 * the same submodel element type as specified in {@link SubmodelElementList.typeValueListElement}.
 *
 * Constraint `AASd-109`:
 * If {@link SubmodelElementList.typeValueListElement} is equal to
 * {@link AasSubmodelElements.Property} or
 * {@link AasSubmodelElements.Range}
 * {@link SubmodelElementList.valueTypeListElement} shall be set and all first
 * level child elements in the {@link SubmodelElementList} shall have
 * the value type as specified in {@link SubmodelElementList.valueTypeListElement}.
 */
export class SubmodelElementList extends Class implements ISubmodelElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Defines whether order in list is relevant. If {@link orderRelevant} = `False`
   * then the list is representing a set or a bag.
   *
   * @remarks
   * Default: `True`
   */
  orderRelevant: boolean | null;

  /**
   * Semantic ID the submodel elements contained in the list match to.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticIdListElement: Reference | null;

  /**
   * The submodel element type of the submodel elements contained in the list.
   */
  typeValueListElement: AasSubmodelElements;

  /**
   * The value type of the submodel element contained in the list.
   */
  valueTypeListElement: DataTypeDefXsd | null;

  /**
   * Submodel element contained in the list.
   *
   * @remarks
   * The list is ordered.
   */
  value: Array<ISubmodelElement> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link value} if it is set, or yield nothing.
   */
  *overValueOrEmpty(): IterableIterator<ISubmodelElement> {
    if (this.value !== null) {
      yield* this.value;
    }
    return;
  }

  /**
   * @returns {@link orderRelevant} if set or the default value otherwise.
   */
  orderRelevantOrDefault(): boolean {
    return this.orderRelevant !== null ? this.orderRelevant : true;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.semanticIdListElement !== null) {
      yield this.semanticIdListElement;
    }

    if (this.value !== null) {
      yield* this.value;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.semanticIdListElement !== null) {
      yield this.semanticIdListElement;

      yield* this.semanticIdListElement.descend();
    }

    if (this.value !== null) {
      for (const yetYetYetYetYetAnotherItem of this.value) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitSubmodelElementList(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitSubmodelElementListWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformSubmodelElementList(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformSubmodelElementListWithContext(this, context);
  }

  constructor(
    typeValueListElement: AasSubmodelElements,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    orderRelevant: boolean | null = null,
    semanticIdListElement: Reference | null = null,
    valueTypeListElement: DataTypeDefXsd | null = null,
    value: Array<ISubmodelElement> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.typeValueListElement = typeValueListElement;
    this.orderRelevant = orderRelevant;
    this.semanticIdListElement = semanticIdListElement;
    this.valueTypeListElement = valueTypeListElement;
    this.value = value;
  }
}

/**
 * A submodel element collection is a kind of struct, i.e. a a logical encapsulation
 * of multiple named values. It has a fixed number of submodel elements.
 */
export class SubmodelElementCollection extends Class implements ISubmodelElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Submodel element contained in the collection.
   */
  value: Array<ISubmodelElement> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link value} if it is set, or yield nothing.
   */
  *overValueOrEmpty(): IterableIterator<ISubmodelElement> {
    if (this.value !== null) {
      yield* this.value;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.value !== null) {
      yield* this.value;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.value !== null) {
      for (const yetYetYetYetYetAnotherItem of this.value) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitSubmodelElementCollection(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitSubmodelElementCollectionWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformSubmodelElementCollection(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformSubmodelElementCollectionWithContext(this, context);
  }

  constructor(
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    value: Array<ISubmodelElement> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.value = value;
  }
}

/**
 * A data element is a submodel element that is not further composed out of
 * other submodel elements.
 *
 * @remarks
 * A data element is a submodel element that has a value. The type of value differs
 * for different subtypes of data elements.
 *
 * Constraint `AASd-090`:
 * For data elements {@link category} shall be one of the following
 * values: `CONSTANT`, `PARAMETER` or `VARIABLE`.
 *
 * Default: `VARIABLE`
 */
export interface IDataElement extends ISubmodelElement {
  categoryOrDefault(): string;
}

/**
 * A property is a data element that has a single value.
 *
 * Constraint `AASd-007`:
 * If both, the {@link Property.value} and the {@link Property.valueId} are
 * present then the value of {@link Property.value} needs to be identical to
 * the value of the referenced coded value in {@link Property.valueId}.
 */
export class Property extends Class implements IDataElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Data type of the value
   */
  valueType: DataTypeDefXsd;

  /**
   * The value of the property instance.
   */
  value: string | null;

  /**
   * Reference to the global unique ID of a coded value.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  valueId: Reference | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * @returns {@link category} if set or the default value otherwise.
   */
  categoryOrDefault(): string {
    return this.category !== null ? this.category : "VARIABLE";
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.valueId !== null) {
      yield this.valueId;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.valueId !== null) {
      yield this.valueId;

      yield* this.valueId.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitProperty(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitPropertyWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformProperty(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformPropertyWithContext(this, context);
  }

  constructor(
    valueType: DataTypeDefXsd,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    value: string | null = null,
    valueId: Reference | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.valueType = valueType;
    this.value = value;
    this.valueId = valueId;
  }
}

/**
 * A property is a data element that has a multi-language value.
 *
 * Constraint `AASd-012`:
 * If both the {@link MultiLanguageProperty.value} and the {@link MultiLanguageProperty.valueId} are present then for each
 * string in a specific language the meaning must be the same as specified in
 * {@link MultiLanguageProperty.valueId}.
 */
export class MultiLanguageProperty extends Class implements IDataElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * The value of the property instance.
   */
  value: Array<LangStringTextType> | null;

  /**
   * Reference to the global unique ID of a coded value.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  valueId: Reference | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link value} if it is set, or yield nothing.
   */
  *overValueOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.value !== null) {
      yield* this.value;
    }
    return;
  }

  /**
   * @returns {@link category} if set or the default value otherwise.
   */
  categoryOrDefault(): string {
    return this.category !== null ? this.category : "VARIABLE";
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.value !== null) {
      yield* this.value;
    }

    if (this.valueId !== null) {
      yield this.valueId;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.value !== null) {
      for (const yetYetYetYetYetAnotherItem of this.value) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }

    if (this.valueId !== null) {
      yield this.valueId;

      yield* this.valueId.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitMultiLanguageProperty(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitMultiLanguagePropertyWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformMultiLanguageProperty(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformMultiLanguagePropertyWithContext(this, context);
  }

  constructor(
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    value: Array<LangStringTextType> | null = null,
    valueId: Reference | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.value = value;
    this.valueId = valueId;
  }
}

/**
 * A range data element is a data element that defines a range with min and max.
 */
export class Range extends Class implements IDataElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Data type of the min und max
   */
  valueType: DataTypeDefXsd;

  /**
   * The minimum value of the range.
   *
   * @remarks
   * If the min value is missing, then the value is assumed to be negative infinite.
   */
  min: string | null;

  /**
   * The maximum value of the range.
   *
   * @remarks
   * If the max value is missing, then the value is assumed to be positive infinite.
   */
  max: string | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * @returns {@link category} if set or the default value otherwise.
   */
  categoryOrDefault(): string {
    return this.category !== null ? this.category : "VARIABLE";
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitRange(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitRangeWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformRange(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformRangeWithContext(this, context);
  }

  constructor(
    valueType: DataTypeDefXsd,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    min: string | null = null,
    max: string | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.valueType = valueType;
    this.min = min;
    this.max = max;
  }
}

/**
 * A reference element is a data element that defines a logical reference to another
 * element within the same or another AAS or a reference to an external object or
 * entity.
 */
export class ReferenceElement extends Class implements IDataElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Global reference to an external object or entity or a logical reference to
   * another element within the same or another AAS (i.e. a model reference to
   * a Referable).
   */
  value: Reference | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * @returns {@link category} if set or the default value otherwise.
   */
  categoryOrDefault(): string {
    return this.category !== null ? this.category : "VARIABLE";
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.value !== null) {
      yield this.value;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.value !== null) {
      yield this.value;

      yield* this.value.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitReferenceElement(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitReferenceElementWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformReferenceElement(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformReferenceElementWithContext(this, context);
  }

  constructor(
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    value: Reference | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.value = value;
  }
}

/**
 * A {@link Blob} is a data element that represents a file that is contained with its
 * source code in the value attribute.
 */
export class Blob extends Class implements IDataElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * The value of the {@link Blob} instance of a blob data element.
   *
   * @remarks
   * **Note**:
   * In contrast to the file property the file content is stored directly as value
   * in the {@link Blob} data element.
   */
  value: Uint8Array | null;

  /**
   * Content type of the content of the {@link Blob}.
   *
   * @remarks
   * The content type (MIME type) states which file extensions the file can have.
   *
   * Valid values are content types like e.g. `application/json`, `application/xls`,
   * `image/jpg`.
   *
   * The allowed values are defined as in RFC2046.
   */
  contentType: string;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * @returns {@link category} if set or the default value otherwise.
   */
  categoryOrDefault(): string {
    return this.category !== null ? this.category : "VARIABLE";
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitBlob(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitBlobWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformBlob(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformBlobWithContext(this, context);
  }

  constructor(
    contentType: string,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    value: Uint8Array | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.contentType = contentType;
    this.value = value;
  }
}

/**
 * A File is a data element that represents an address to a file (a locator).
 *
 * @remarks
 * The value is an URI that can represent an absolute or relative path.
 */
export class File extends Class implements IDataElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Path and name of the referenced file (with file extension).
   *
   * @remarks
   * The path can be absolute or relative.
   */
  value: string | null;

  /**
   * Content type of the content of the file.
   *
   * @remarks
   * The content type states which file extensions the file can have.
   */
  contentType: string;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * @returns {@link category} if set or the default value otherwise.
   */
  categoryOrDefault(): string {
    return this.category !== null ? this.category : "VARIABLE";
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitFile(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitFileWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformFile(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformFileWithContext(this, context);
  }

  constructor(
    contentType: string,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    value: string | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.contentType = contentType;
    this.value = value;
  }
}

/**
 * An annotated relationship element is a relationship element that can be annotated
 * with additional data elements.
 */
export class AnnotatedRelationshipElement
  extends Class
  implements IRelationshipElement
{
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Reference to the first element in the relationship taking the role of the subject.
   */
  first: Reference;

  /**
   * Reference to the second element in the relationship taking the role of the object.
   */
  second: Reference;

  /**
   * A data element that represents an annotation that holds for the relationship
   * between the two elements
   */
  annotations: Array<IDataElement> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link annotations} if it is set, or yield nothing.
   */
  *overAnnotationsOrEmpty(): IterableIterator<IDataElement> {
    if (this.annotations !== null) {
      yield* this.annotations;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    yield this.first;

    yield this.second;

    if (this.annotations !== null) {
      yield* this.annotations;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    yield this.first;

    yield* this.first.descend();

    yield this.second;

    yield* this.second.descend();

    if (this.annotations !== null) {
      for (const yetYetYetYetYetAnotherItem of this.annotations) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitAnnotatedRelationshipElement(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitAnnotatedRelationshipElementWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformAnnotatedRelationshipElement(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformAnnotatedRelationshipElementWithContext(this, context);
  }

  constructor(
    first: Reference,
    second: Reference,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    annotations: Array<IDataElement> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.first = first;
    this.second = second;
    this.annotations = annotations;
  }
}

/**
 * An entity is a submodel element that is used to model entities.
 *
 * Constraint `AASd-014`:
 * Either the attribute {@link Entity.globalAssetId} or {@link Entity.specificAssetIds}
 * of an {@link Entity} must be set if {@link Entity.entityType} is set to
 * {@link EntityType.SelfManagedEntity}. They are not existing otherwise.
 */
export class Entity extends Class implements ISubmodelElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Describes statements applicable to the entity by a set of submodel elements,
   * typically with a qualified value.
   */
  statements: Array<ISubmodelElement> | null;

  /**
   * Describes whether the entity is a co-managed entity or a self-managed entity.
   */
  entityType: EntityType;

  /**
   * Global identifier of the asset the entity is representing.
   *
   * @remarks
   * **Note**:
   * This is a global reference.
   */
  globalAssetId: string | null;

  /**
   * Reference to a specific asset ID representing a supplementary identifier
   * of the asset represented by the Asset Administration Shell.
   */
  specificAssetIds: Array<SpecificAssetId> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link statements} if it is set, or yield nothing.
   */
  *overStatementsOrEmpty(): IterableIterator<ISubmodelElement> {
    if (this.statements !== null) {
      yield* this.statements;
    }
    return;
  }

  /**
   * Yield from {@link specificAssetIds} if it is set, or yield nothing.
   */
  *overSpecificAssetIdsOrEmpty(): IterableIterator<SpecificAssetId> {
    if (this.specificAssetIds !== null) {
      yield* this.specificAssetIds;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.statements !== null) {
      yield* this.statements;
    }

    if (this.specificAssetIds !== null) {
      yield* this.specificAssetIds;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.statements !== null) {
      for (const yetYetYetYetYetAnotherItem of this.statements) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }

    if (this.specificAssetIds !== null) {
      for (const yetYetYetYetYetYetAnotherItem of this.specificAssetIds) {
        yield yetYetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitEntity(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitEntityWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformEntity(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformEntityWithContext(this, context);
  }

  constructor(
    entityType: EntityType,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    statements: Array<ISubmodelElement> | null = null,
    globalAssetId: string | null = null,
    specificAssetIds: Array<SpecificAssetId> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.statements = statements;
    this.entityType = entityType;
    this.globalAssetId = globalAssetId;
    this.specificAssetIds = specificAssetIds;
  }
}

/**
 * Enumeration for denoting whether an entity is a self-managed entity or a co-managed
 * entity.
 */
export enum EntityType {
  /**
   * For co-managed entities there is no separate AAS. Co-managed entities need to be
   * part of a self-managed entity.
   */
  CoManagedEntity = 0,
  /**
   * Self-Managed Entities have their own AAS but can be part of the bill of material of
   * a composite self-managed entity.
   *
   * @remarks
   *
   * The asset of an I4.0 Component is a self-managed entity per definition.
   */
  SelfManagedEntity
}

/**
 * Iterate over the literals of {@link EntityType}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overEntityType(): IterableIterator<EntityType> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <EntityType>0; // CoManagedEntity
  yield <EntityType>1; // SelfManagedEntity
}

/**
 * Direction
 *
 * @remarks
 * **Note**:
 * This element is experimental and therefore may be subject to change or may be
 * removed completely in future versions of the meta-model.
 */
export enum Direction {
  /**
   * Input direction.
   */
  Input = 0,
  /**
   * Output direction
   */
  Output
}

/**
 * Iterate over the literals of {@link Direction}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overDirection(): IterableIterator<Direction> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <Direction>0; // Input
  yield <Direction>1; // Output
}

/**
 * State of an event
 *
 * @remarks
 * **Note**:
 * This element is experimental and therefore may be subject to change or may be
 * removed completely in future versions of the meta-model.
 */
export enum StateOfEvent {
  /**
   * Event is on
   */
  On = 0,
  /**
   * Event is off.
   */
  Off
}

/**
 * Iterate over the literals of {@link StateOfEvent}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overStateOfEvent(): IterableIterator<StateOfEvent> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <StateOfEvent>0; // On
  yield <StateOfEvent>1; // Off
}

/**
 * Defines the necessary information of an event instance sent out or received.
 *
 * @remarks
 * **Note**:
 * This element is experimental and therefore may be subject to change or may be
 * removed completely in future versions of the meta-model.
 */
export class EventPayload extends Class {
  /**
   * Reference to the source event element, including identification of
   * {@link AssetAdministrationShell}, {@link Submodel},
   * {@link ISubmodelElement}'s.
   */
  source: Reference;

  /**
   * {@link IHasSemantics.semanticId} of the source event element, if available
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  sourceSemanticId: Reference | null;

  /**
   * Reference to the referable, which defines the scope of the event.
   *
   * @remarks
   * Can be {@link AssetAdministrationShell}, {@link Submodel} or
   * {@link ISubmodelElement}.
   */
  observableReference: Reference;

  /**
   * {@link IHasSemantics.semanticId} of the referable which defines the scope of
   * the event, if available.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  observableSemanticId: Reference | null;

  /**
   * Information for the outer message infrastructure for scheduling the event to
   * the respective communication channel.
   */
  topic: string | null;

  /**
   * Subject, who/which initiated the creation.
   *
   * @remarks
   * **Note**:
   * This is an external reference.
   */
  subjectId: Reference | null;

  /**
   * Timestamp in UTC, when this event was triggered.
   */
  timeStamp: string;

  /**
   * Event specific payload.
   */
  payload: Uint8Array | null;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    yield this.source;

    if (this.sourceSemanticId !== null) {
      yield this.sourceSemanticId;
    }

    yield this.observableReference;

    if (this.observableSemanticId !== null) {
      yield this.observableSemanticId;
    }

    if (this.subjectId !== null) {
      yield this.subjectId;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    yield this.source;

    yield* this.source.descend();

    if (this.sourceSemanticId !== null) {
      yield this.sourceSemanticId;

      yield* this.sourceSemanticId.descend();
    }

    yield this.observableReference;

    yield* this.observableReference.descend();

    if (this.observableSemanticId !== null) {
      yield this.observableSemanticId;

      yield* this.observableSemanticId.descend();
    }

    if (this.subjectId !== null) {
      yield this.subjectId;

      yield* this.subjectId.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitEventPayload(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitEventPayloadWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformEventPayload(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformEventPayloadWithContext(this, context);
  }

  constructor(
    source: Reference,
    observableReference: Reference,
    timeStamp: string,
    sourceSemanticId: Reference | null = null,
    observableSemanticId: Reference | null = null,
    topic: string | null = null,
    subjectId: Reference | null = null,
    payload: Uint8Array | null = null
  ) {
    super();
    this.source = source;
    this.observableReference = observableReference;
    this.timeStamp = timeStamp;
    this.sourceSemanticId = sourceSemanticId;
    this.observableSemanticId = observableSemanticId;
    this.topic = topic;
    this.subjectId = subjectId;
    this.payload = payload;
  }
}

/**
 * An event element.
 *
 * @remarks
 * **Note**:
 * This element is experimental and therefore may be subject to change or may be
 * removed completely in future versions of the meta-model.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEventElement extends ISubmodelElement {
  // Intentionally empty.
}

/**
 * A basic event element.
 *
 * @remarks
 * **Note**:
 * This element is experimental and therefore may be subject to change or may be
 * removed completely in future versions of the meta-model.
 */
export class BasicEventElement extends Class implements IEventElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Reference to the {@link IReferable}, which defines the scope of the event.
   * Can be {@link AssetAdministrationShell}, {@link Submodel}, or
   * {@link ISubmodelElement}.
   *
   * @remarks
   * Reference to a referable, e.g., a data element or
   * a submodel, that is being observed.
   */
  observed: Reference;

  /**
   * Direction of event.
   *
   * @remarks
   * Can be `{ Input, Output }`.
   */
  direction: Direction;

  /**
   * State of event.
   *
   * @remarks
   * Can be `{ On, Off }`.
   */
  state: StateOfEvent;

  /**
   * Information for the outer message infrastructure for scheduling the event to the
   * respective communication channel.
   */
  messageTopic: string | null;

  /**
   * Information, which outer message infrastructure shall handle messages for
   * the {@link IEventElement}. Refers to a {@link Submodel},
   * {@link SubmodelElementList}, {@link SubmodelElementCollection} or
   * {@link Entity}, which contains {@link IDataElement}'s describing
   * the proprietary specification for the message broker.
   *
   * @remarks
   * **Note**:
   * For different message infrastructure, e.g., OPC UA or MQTT or AMQP, this
   * proprietary specification could be standardized by having respective Submodels.
   */
  messageBroker: Reference | null;

  /**
   * Timestamp in UTC, when the last event was received (input direction) or sent
   * (output direction).
   */
  lastUpdate: string | null;

  /**
   * For input direction, reports on the maximum frequency, the software entity behind
   * the respective Referable can handle input events.
   *
   * @remarks
   * For output events, specifies the maximum frequency of outputting this event to
   * an outer infrastructure.
   *
   * Might be not specified, that is, there is no minimum interval.
   */
  minInterval: string | null;

  /**
   * For input direction: not applicable.
   *
   * @remarks
   * For output direction: maximum interval in time, the respective Referable shall send
   * an update of the status of the event, even if no other trigger condition for
   * the event was not met.
   *
   * Might be not specified, that is, there is no maximum interval
   */
  maxInterval: string | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    yield this.observed;

    if (this.messageBroker !== null) {
      yield this.messageBroker;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    yield this.observed;

    yield* this.observed.descend();

    if (this.messageBroker !== null) {
      yield this.messageBroker;

      yield* this.messageBroker.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitBasicEventElement(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitBasicEventElementWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformBasicEventElement(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformBasicEventElementWithContext(this, context);
  }

  constructor(
    observed: Reference,
    direction: Direction,
    state: StateOfEvent,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    messageTopic: string | null = null,
    messageBroker: Reference | null = null,
    lastUpdate: string | null = null,
    minInterval: string | null = null,
    maxInterval: string | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.observed = observed;
    this.direction = direction;
    this.state = state;
    this.messageTopic = messageTopic;
    this.messageBroker = messageBroker;
    this.lastUpdate = lastUpdate;
    this.minInterval = minInterval;
    this.maxInterval = maxInterval;
  }
}

/**
 * An operation is a submodel element with input and output variables.
 *
 * Constraint `AASd-134`:
 * For an {@link Operation} the {@link IReferable.idShort} of all
 * {@link OperationVariable.value}'s in
 * {@link Operation.inputVariables}, {@link Operation.outputVariables}
 * and {@link Operation.inoutputVariables} shall be unique.
 */
export class Operation extends Class implements ISubmodelElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Input parameter of the operation.
   */
  inputVariables: Array<OperationVariable> | null;

  /**
   * Output parameter of the operation.
   */
  outputVariables: Array<OperationVariable> | null;

  /**
   * Parameter that is input and output of the operation.
   */
  inoutputVariables: Array<OperationVariable> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link inputVariables} if it is set, or yield nothing.
   */
  *overInputVariablesOrEmpty(): IterableIterator<OperationVariable> {
    if (this.inputVariables !== null) {
      yield* this.inputVariables;
    }
    return;
  }

  /**
   * Yield from {@link outputVariables} if it is set, or yield nothing.
   */
  *overOutputVariablesOrEmpty(): IterableIterator<OperationVariable> {
    if (this.outputVariables !== null) {
      yield* this.outputVariables;
    }
    return;
  }

  /**
   * Yield from {@link inoutputVariables} if it is set, or yield nothing.
   */
  *overInoutputVariablesOrEmpty(): IterableIterator<OperationVariable> {
    if (this.inoutputVariables !== null) {
      yield* this.inoutputVariables;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.inputVariables !== null) {
      yield* this.inputVariables;
    }

    if (this.outputVariables !== null) {
      yield* this.outputVariables;
    }

    if (this.inoutputVariables !== null) {
      yield* this.inoutputVariables;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }

    if (this.inputVariables !== null) {
      for (const yetYetYetYetYetAnotherItem of this.inputVariables) {
        yield yetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetAnotherItem.descend();
      }
    }

    if (this.outputVariables !== null) {
      for (const yetYetYetYetYetYetAnotherItem of this.outputVariables) {
        yield yetYetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetYetAnotherItem.descend();
      }
    }

    if (this.inoutputVariables !== null) {
      for (const yetYetYetYetYetYetYetAnotherItem of this.inoutputVariables) {
        yield yetYetYetYetYetYetYetAnotherItem;

        yield* yetYetYetYetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitOperation(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitOperationWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformOperation(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformOperationWithContext(this, context);
  }

  constructor(
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    inputVariables: Array<OperationVariable> | null = null,
    outputVariables: Array<OperationVariable> | null = null,
    inoutputVariables: Array<OperationVariable> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.inputVariables = inputVariables;
    this.outputVariables = outputVariables;
    this.inoutputVariables = inoutputVariables;
  }
}

/**
 * The value of an operation variable is a submodel element that is used as input
 * and/or output variable of an operation.
 */
export class OperationVariable extends Class {
  /**
   * Describes an argument or result of an operation via a submodel element
   */
  value: ISubmodelElement;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    yield this.value;
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    yield this.value;

    yield* this.value.descend();
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitOperationVariable(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitOperationVariableWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformOperationVariable(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformOperationVariableWithContext(this, context);
  }

  constructor(value: ISubmodelElement) {
    super();
    this.value = value;
  }
}

/**
 * A capability is the implementation-independent description of the potential of an
 * asset to achieve a certain effect in the physical or virtual world.
 *
 * @remarks
 * **Note**:
 * The {@link Capability.semanticId} of a capability is typically an ontology.
 * Thus, reasoning on capabilities is enabled.
 */
export class Capability extends Class implements ISubmodelElement {
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Identifier of the semantic definition of the element. It is called semantic ID
   * of the element or also main semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  semanticId: Reference | null;

  /**
   * Identifier of a supplemental semantic definition of the element.
   * It is called supplemental semantic ID of the element.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  supplementalSemanticIds: Array<Reference> | null;

  /**
   * Additional qualification of a qualifiable element.
   *
   * Constraint `AASd-021`:
   * Every qualifiable can only have one qualifier with the same
   * {@link Qualifier.type}.
   */
  qualifiers: Array<Qualifier> | null;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link supplementalSemanticIds} if it is set, or yield nothing.
   */
  *overSupplementalSemanticIdsOrEmpty(): IterableIterator<Reference> {
    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }
    return;
  }

  /**
   * Yield from {@link qualifiers} if it is set, or yield nothing.
   */
  *overQualifiersOrEmpty(): IterableIterator<Qualifier> {
    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.semanticId !== null) {
      yield this.semanticId;
    }

    if (this.supplementalSemanticIds !== null) {
      yield* this.supplementalSemanticIds;
    }

    if (this.qualifiers !== null) {
      yield* this.qualifiers;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.semanticId !== null) {
      yield this.semanticId;

      yield* this.semanticId.descend();
    }

    if (this.supplementalSemanticIds !== null) {
      for (const yetYetAnotherItem of this.supplementalSemanticIds) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.qualifiers !== null) {
      for (const yetYetYetAnotherItem of this.qualifiers) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetYetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetYetYetAnotherItem;

        yield* yetYetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitCapability(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitCapabilityWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformCapability(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformCapabilityWithContext(this, context);
  }

  constructor(
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    semanticId: Reference | null = null,
    supplementalSemanticIds: Array<Reference> | null = null,
    qualifiers: Array<Qualifier> | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.semanticId = semanticId;
    this.supplementalSemanticIds = supplementalSemanticIds;
    this.qualifiers = qualifiers;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
  }
}

/**
 * The semantics of a property or other elements that may have a semantic description
 * is defined by a concept description.
 *
 * @remarks
 * The description of the concept should follow a standardized schema (realized as
 * data specification template).
 *
 * Constraint `AASc-3a-004`:
 * For a {@link ConceptDescription} with {@link ConceptDescription.category} `PROPERTY` or
 * `VALUE` using data specification IEC61360,
 * the {@link DataSpecificationIec61360.dataType} is mandatory and shall be
 * one of: `DATE`, `STRING`, `STRING_TRANSLATABLE`, `INTEGER_MEASURE`,
 * `INTEGER_COUNT`, `INTEGER_CURRENCY`, `REAL_MEASURE`, `REAL_COUNT`,
 * `REAL_CURRENCY`, `BOOLEAN`, `RATIONAL`, `RATIONAL_MEASURE`,
 * `TIME`, `TIMESTAMP`.
 *
 * **Note**:
 * Note: categories are deprecated since V3.0 of Part 1a of the document series
 * "Details of the Asset Administration Shell".
 *
 * Constraint `AASc-3a-005`:
 * For a {@link ConceptDescription} with {@link ConceptDescription.category} `REFERENCE`
 * using data specification template IEC61360,
 * the {@link DataSpecificationIec61360.dataType} shall be
 * one of: `STRING`, `IRI`, `IRDI`.
 *
 * **Note**:
 * Note: categories are deprecated since V3.0 of Part 1a of the document series
 * "Details of the Asset Administration Shell".
 *
 * Constraint `AASc-3a-006`:
 * For a {@link ConceptDescription} with {@link ConceptDescription.category} `DOCUMENT`
 * using data specification IEC61360,
 * the {@link DataSpecificationIec61360.dataType} shall be one of `FILE`,
 * `BLOB`, `HTML`
 *
 * **Note**:
 * Categories are deprecated since V3.0 of Part 1a of the document series
 * "Details of the Asset Administration Shell".
 *
 * Constraint `AASc-3a-007`:
 * For a {@link ConceptDescription} with {@link ConceptDescription.category} `QUALIFIER_TYPE`
 * using data specification IEC61360,
 * the {@link DataSpecificationIec61360.dataType} is mandatory and shall be
 * defined.
 *
 * **Note**:
 * Categories are deprecated since V3.0 of Part 1a of the document series
 * "Details of the Asset Administration Shell".
 *
 * Constraint `AASc-3a-008`:
 * For a {@link ConceptDescription} using data specification template IEC61360,
 * {@link DataSpecificationIec61360.definition} is mandatory and shall be
 * defined at least in English.
 *
 * Exception: The concept description describes a value, i.e.
 * {@link DataSpecificationIec61360.value} is defined.
 *
 * Constraint `AASc-3a-003`:
 * For a {@link ConceptDescription} using data specification template IEC61360,
 * referenced via {@link DataSpecificationIec61360.valueList}
 * {@link ValueReferencePair.valueId}
 * the {@link DataSpecificationIec61360.value} shall be set.
 */
export class ConceptDescription
  extends Class
  implements IIdentifiable, IHasDataSpecification
{
  /**
   * An extension of the element.
   */
  extensions: Array<Extension> | null;

  /**
   * The category is a value that gives further meta information
   * w.r.t. to the class of the element.
   * It affects the expected existence of attributes and the applicability of
   * constraints.
   *
   * @remarks
   * **Note**:
   * The category is not identical to the semantic definition
   * ({@link IHasSemantics}) of an element. The category e.g. could denote that
   * the element is a measurement value whereas the semantic definition of
   * the element would denote that it is the measured temperature.
   */
  category: string | null;

  /**
   * In case of identifiables this attribute is a short name of the element.
   * In case of referable this ID is an identifying string of the element within
   * its name space.
   *
   * @remarks
   * **Note**:
   * In case the element is a property and the property has a semantic definition
   * ({@link IHasSemantics.semanticId}) conformant to IEC61360
   * the {@link IReferable.idShort} is typically identical to the short name in English.
   */
  idShort: string | null;

  /**
   * Display name. Can be provided in several languages.
   */
  displayName: Array<LangStringNameType> | null;

  /**
   * Description or comments on the element.
   *
   * @remarks
   * The description can be provided in several languages.
   *
   * If no description is defined, then the definition of the concept
   * description that defines the semantics of the element is used.
   *
   * Additional information can be provided, e.g., if the element is
   * qualified and which qualifier types can be expected in which
   * context or which additional data specification templates are
   * provided.
   */
  description: Array<LangStringTextType> | null;

  /**
   * Administrative information of an identifiable element.
   *
   * @remarks
   * **Note**:
   * Some of the administrative information like the version number might need to
   * be part of the identification.
   */
  administration: AdministrativeInformation | null;

  /**
   * The globally unique identification of the element.
   */
  id: string;

  /**
   * Embedded data specification.
   */
  embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null;

  /**
   * Reference to an external definition the concept is compatible to or was derived
   * from.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   *
   * **Note**:
   * Compare to is-case-of relationship in ISO 13584-32 & IEC EN 61360
   */
  isCaseOf: Array<Reference> | null;

  /**
   * Yield from {@link extensions} if it is set, or yield nothing.
   */
  *overExtensionsOrEmpty(): IterableIterator<Extension> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }
    return;
  }

  /**
   * Yield from {@link displayName} if it is set, or yield nothing.
   */
  *overDisplayNameOrEmpty(): IterableIterator<LangStringNameType> {
    if (this.displayName !== null) {
      yield* this.displayName;
    }
    return;
  }

  /**
   * Yield from {@link description} if it is set, or yield nothing.
   */
  *overDescriptionOrEmpty(): IterableIterator<LangStringTextType> {
    if (this.description !== null) {
      yield* this.description;
    }
    return;
  }

  /**
   * Yield from {@link embeddedDataSpecifications} if it is set, or yield nothing.
   */
  *overEmbeddedDataSpecificationsOrEmpty(): IterableIterator<EmbeddedDataSpecification> {
    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }
    return;
  }

  /**
   * Yield from {@link isCaseOf} if it is set, or yield nothing.
   */
  *overIsCaseOfOrEmpty(): IterableIterator<Reference> {
    if (this.isCaseOf !== null) {
      yield* this.isCaseOf;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.extensions !== null) {
      yield* this.extensions;
    }

    if (this.displayName !== null) {
      yield* this.displayName;
    }

    if (this.description !== null) {
      yield* this.description;
    }

    if (this.administration !== null) {
      yield this.administration;
    }

    if (this.embeddedDataSpecifications !== null) {
      yield* this.embeddedDataSpecifications;
    }

    if (this.isCaseOf !== null) {
      yield* this.isCaseOf;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.extensions !== null) {
      for (const anItem of this.extensions) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.displayName !== null) {
      for (const anotherItem of this.displayName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.description !== null) {
      for (const yetAnotherItem of this.description) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.administration !== null) {
      yield this.administration;

      yield* this.administration.descend();
    }

    if (this.embeddedDataSpecifications !== null) {
      for (const yetYetAnotherItem of this.embeddedDataSpecifications) {
        yield yetYetAnotherItem;

        yield* yetYetAnotherItem.descend();
      }
    }

    if (this.isCaseOf !== null) {
      for (const yetYetYetAnotherItem of this.isCaseOf) {
        yield yetYetYetAnotherItem;

        yield* yetYetYetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitConceptDescription(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitConceptDescriptionWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformConceptDescription(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformConceptDescriptionWithContext(this, context);
  }

  constructor(
    id: string,
    extensions: Array<Extension> | null = null,
    category: string | null = null,
    idShort: string | null = null,
    displayName: Array<LangStringNameType> | null = null,
    description: Array<LangStringTextType> | null = null,
    administration: AdministrativeInformation | null = null,
    embeddedDataSpecifications: Array<EmbeddedDataSpecification> | null = null,
    isCaseOf: Array<Reference> | null = null
  ) {
    super();
    this.extensions = extensions;
    this.idShort = idShort;
    this.displayName = displayName;
    this.category = category;
    this.description = description;
    this.id = id;
    this.administration = administration;
    this.embeddedDataSpecifications = embeddedDataSpecifications;
    this.isCaseOf = isCaseOf;
  }
}

/**
 * Reference types
 */
export enum ReferenceTypes {
  /**
   * External reference.
   */
  ExternalReference = 0,
  /**
   * Model reference.
   */
  ModelReference
}

/**
 * Iterate over the literals of {@link ReferenceTypes}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overReferenceTypes(): IterableIterator<ReferenceTypes> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <ReferenceTypes>0; // ExternalReference
  yield <ReferenceTypes>1; // ModelReference
}

/**
 * Reference to either a model element of the same or another AAS or to an external
 * entity.
 *
 * @remarks
 * A reference is an ordered list of keys.
 *
 * A model reference is an ordered list of keys, each key referencing an element. The
 * complete list of keys may for example be concatenated to a path that then gives
 * unique access to an element.
 *
 * An external reference is a reference to an external entity.
 *
 * Constraint `AASd-121`:
 * For {@link Reference}'s the value of {@link Key.type} of the first key of "
 * {@link Reference.keys} shall be one of {@link constants!GLOBALLY_IDENTIFIABLES}.
 *
 * Constraint `AASd-122`:
 * For external references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ExternalReference}, the value
 * of {@link Key.type} of the first key of {@link Reference.keys} shall be one of
 * {@link constants!GENERIC_GLOBALLY_IDENTIFIABLES}.
 *
 * Constraint `AASd-123`:
 * For model references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ModelReference}, the value
 * of {@link Key.type} of the first key of {@link Reference.keys} shall be one of
 * {@link constants!AAS_IDENTIFIABLES}.
 *
 * Constraint `AASd-124`:
 * For external references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ExternalReference}, the last
 * key of {@link Reference.keys} shall be either one of
 * {@link constants!GENERIC_GLOBALLY_IDENTIFIABLES} or one of
 * {@link constants!GENERIC_FRAGMENT_KEYS}.
 *
 * Constraint `AASd-125`:
 * For model references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ModelReference}, with more
 * than one key in {@link Reference.keys} the value of {@link Key.type}
 * of each of the keys following the first
 * key of {@link Reference.keys} shall be one of {@link constants!FRAGMENT_KEYS}.
 *
 * **Note**:
 * Constraint AASd-125 ensures that the shortest path is used.
 *
 * Constraint `AASd-126`:
 * For model references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ModelReference}, with more
 * than one key in {@link Reference.keys} the value of {@link Key.type}
 * of the last key in the reference key chain may be
 * one of {@link constants!GENERIC_FRAGMENT_KEYS} or no key at all
 * shall have a value out of {@link constants!GENERIC_FRAGMENT_KEYS}.
 *
 * Constraint `AASd-127`:
 * For model references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ModelReference}, with more
 * than one key in {@link Reference.keys} a key with {@link Key.type}
 * {@link KeyTypes.FragmentReference} shall be preceded by a key with
 * {@link Key.type} {@link KeyTypes.File} or {@link KeyTypes.Blob}. All other
 * AAS fragments, i.e. {@link Key.type} values
 * out of {@link constants!AAS_SUBMODEL_ELEMENTS_AS_KEYS}, do not support fragments.
 *
 * **Note**:
 * Which kind of fragments are supported depends on the content type and the
 * specification of allowed fragment identifiers for the corresponding resource
 * being referenced via the reference.
 *
 * Constraint `AASd-128`:
 * For model references, i.e. {@link Reference}'s with
 * {@link Reference.type} = {@link ReferenceTypes.ModelReference}, the
 * {@link Key.value} of a {@link Key} preceded by a {@link Key} with
 * {@link Key.type} = {@link KeyTypes.SubmodelElementList} is an integer
 * number denoting the position in the array of the submodel element list.
 */
export class Reference extends Class {
  /**
   * Type of the reference.
   *
   * @remarks
   * Denotes, whether reference is an external reference or a model reference.
   */
  type: ReferenceTypes;

  /**
   * {@link IHasSemantics.semanticId} of the referenced model element
   * ({@link type} = {@link ReferenceTypes.ModelReference}).
   *
   * @remarks
   * For external references there typically is no semantic ID.
   *
   * **Note**:
   * It is recommended to use a external reference.
   */
  referredSemanticId: Reference | null;

  /**
   * Unique references in their name space.
   */
  keys: Array<Key>;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.referredSemanticId !== null) {
      yield this.referredSemanticId;
    }

    yield* this.keys;
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.referredSemanticId !== null) {
      yield this.referredSemanticId;

      yield* this.referredSemanticId.descend();
    }

    for (const anItem of this.keys) {
      yield anItem;

      yield* anItem.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitReference(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitReferenceWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformReference(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformReferenceWithContext(this, context);
  }

  constructor(
    type: ReferenceTypes,
    keys: Array<Key>,
    referredSemanticId: Reference | null = null
  ) {
    super();
    this.type = type;
    this.keys = keys;
    this.referredSemanticId = referredSemanticId;
  }
}

/**
 * A key is a reference to an element by its ID.
 */
export class Key extends Class {
  /**
   * Denotes which kind of entity is referenced.
   *
   * @remarks
   * In case {@link type} = {@link KeyTypes.GlobalReference},
   * the key represents a reference to a source that can be globally identified.
   *
   * In case {@link type} = {@link KeyTypes.FragmentReference} the key represents
   * a bookmark or a similar local identifier within its parent element as specified
   * by the key that precedes this key.
   *
   * In all other cases the key references a model element of the same or of another AAS.
   * The name of the model element is explicitly listed.
   */
  type: KeyTypes;

  /**
   * The key value, for example an IRDI or an URI
   */
  value: string;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitKey(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitKeyWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformKey(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformKeyWithContext(this, context);
  }

  constructor(type: KeyTypes, value: string) {
    super();
    this.type = type;
    this.value = value;
  }
}

/**
 * Enumeration of different key value types within a key.
 */
export enum KeyTypes {
  AnnotatedRelationshipElement = 0,
  AssetAdministrationShell,
  BasicEventElement,
  Blob,
  Capability,
  ConceptDescription,
  /**
   * Data element.
   *
   * @remarks
   *
   * **Note**:
   * Data Element is abstract, *i.e.* if a key uses {@link DataElement}
   * the reference may be a Property, a File etc.
   */
  DataElement,
  Entity,
  /**
   * Event.
   *
   * @remarks
   *
   * **Note**:
   * {@link IEventElement} is abstract.
   */
  EventElement,
  File,
  /**
   * Bookmark or a similar local identifier of a subordinate part of
   * a primary resource
   */
  FragmentReference,
  GlobalReference,
  /**
   * Identifiable.
   *
   * @remarks
   *
   * **Note**:
   * Identifiable is abstract, i.e. if a key uses “Identifiable” the reference
   * may be an Asset Administration Shell, a Submodel or a Concept Description.
   */
  Identifiable,
  /**
   * Property with a value that can be provided in multiple languages
   */
  MultiLanguageProperty,
  Operation,
  Property,
  /**
   * Range with min and max
   */
  Range,
  Referable,
  /**
   * Reference
   */
  ReferenceElement,
  /**
   * Relationship
   */
  RelationshipElement,
  Submodel,
  /**
   * Submodel Element
   *
   * @remarks
   *
   * **Note**:
   * Submodel Element is abstract, *i.e.* if a key uses {@link SubmodelElement}
   * the reference may be a {@link Property}, an {@link Operation} etc.
   */
  SubmodelElement,
  /**
   * Struct of Submodel Elements
   */
  SubmodelElementCollection,
  /**
   * List of Submodel Elements
   */
  SubmodelElementList
}

/**
 * Iterate over the literals of {@link KeyTypes}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overKeyTypes(): IterableIterator<KeyTypes> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <KeyTypes>0; // AnnotatedRelationshipElement
  yield <KeyTypes>1; // AssetAdministrationShell
  yield <KeyTypes>2; // BasicEventElement
  yield <KeyTypes>3; // Blob
  yield <KeyTypes>4; // Capability
  yield <KeyTypes>5; // ConceptDescription
  yield <KeyTypes>6; // DataElement
  yield <KeyTypes>7; // Entity
  yield <KeyTypes>8; // EventElement
  yield <KeyTypes>9; // File
  yield <KeyTypes>10; // FragmentReference
  yield <KeyTypes>11; // GlobalReference
  yield <KeyTypes>12; // Identifiable
  yield <KeyTypes>13; // MultiLanguageProperty
  yield <KeyTypes>14; // Operation
  yield <KeyTypes>15; // Property
  yield <KeyTypes>16; // Range
  yield <KeyTypes>17; // Referable
  yield <KeyTypes>18; // ReferenceElement
  yield <KeyTypes>19; // RelationshipElement
  yield <KeyTypes>20; // Submodel
  yield <KeyTypes>21; // SubmodelElement
  yield <KeyTypes>22; // SubmodelElementCollection
  yield <KeyTypes>23; // SubmodelElementList
}

/**
 * Enumeration listing all XSD anySimpleTypes
 */
export enum DataTypeDefXsd {
  AnyUri = 0,
  Base64Binary,
  Boolean,
  Byte,
  Date,
  DateTime,
  Decimal,
  Double,
  Duration,
  Float,
  GDay,
  GMonth,
  GMonthDay,
  GYear,
  GYearMonth,
  HexBinary,
  Int,
  Integer,
  Long,
  NegativeInteger,
  NonNegativeInteger,
  NonPositiveInteger,
  PositiveInteger,
  Short,
  String,
  Time,
  UnsignedByte,
  UnsignedInt,
  UnsignedLong,
  UnsignedShort
}

/**
 * Iterate over the literals of {@link DataTypeDefXsd}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overDataTypeDefXsd(): IterableIterator<DataTypeDefXsd> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <DataTypeDefXsd>0; // AnyUri
  yield <DataTypeDefXsd>1; // Base64Binary
  yield <DataTypeDefXsd>2; // Boolean
  yield <DataTypeDefXsd>3; // Byte
  yield <DataTypeDefXsd>4; // Date
  yield <DataTypeDefXsd>5; // DateTime
  yield <DataTypeDefXsd>6; // Decimal
  yield <DataTypeDefXsd>7; // Double
  yield <DataTypeDefXsd>8; // Duration
  yield <DataTypeDefXsd>9; // Float
  yield <DataTypeDefXsd>10; // GDay
  yield <DataTypeDefXsd>11; // GMonth
  yield <DataTypeDefXsd>12; // GMonthDay
  yield <DataTypeDefXsd>13; // GYear
  yield <DataTypeDefXsd>14; // GYearMonth
  yield <DataTypeDefXsd>15; // HexBinary
  yield <DataTypeDefXsd>16; // Int
  yield <DataTypeDefXsd>17; // Integer
  yield <DataTypeDefXsd>18; // Long
  yield <DataTypeDefXsd>19; // NegativeInteger
  yield <DataTypeDefXsd>20; // NonNegativeInteger
  yield <DataTypeDefXsd>21; // NonPositiveInteger
  yield <DataTypeDefXsd>22; // PositiveInteger
  yield <DataTypeDefXsd>23; // Short
  yield <DataTypeDefXsd>24; // String
  yield <DataTypeDefXsd>25; // Time
  yield <DataTypeDefXsd>26; // UnsignedByte
  yield <DataTypeDefXsd>27; // UnsignedInt
  yield <DataTypeDefXsd>28; // UnsignedLong
  yield <DataTypeDefXsd>29; // UnsignedShort
}

/**
 * Strings with language tags
 */
export interface IAbstractLangString extends Class {
  /**
   * Language tag conforming to BCP 47
   */
  language: string;

  /**
   * Text in the {@link language}
   */
  text: string;
}

/**
 * String with length 128 maximum and minimum 1 characters and with language tags
 */
export class LangStringNameType extends Class implements IAbstractLangString {
  /**
   * Language tag conforming to BCP 47
   */
  language: string;

  /**
   * Text in the {@link IAbstractLangString.language}
   */
  text: string;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitLangStringNameType(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitLangStringNameTypeWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformLangStringNameType(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformLangStringNameTypeWithContext(this, context);
  }

  constructor(language: string, text: string) {
    super();
    this.language = language;
    this.text = text;
  }
}

/**
 * String with length 1023 maximum and minimum 1 characters and with language tags
 */
export class LangStringTextType extends Class implements IAbstractLangString {
  /**
   * Language tag conforming to BCP 47
   */
  language: string;

  /**
   * Text in the {@link IAbstractLangString.language}
   */
  text: string;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitLangStringTextType(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitLangStringTextTypeWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformLangStringTextType(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformLangStringTextTypeWithContext(this, context);
  }

  constructor(language: string, text: string) {
    super();
    this.language = language;
    this.text = text;
  }
}

/**
 * Container for the sets of different identifiables.
 *
 * @remarks
 * **Note**:
 * w.r.t. file exchange: There is exactly one environment independent on how many
 * files the contained elements are split. If the file is split then there
 * shall be no element with the same identifier in two different files.
 */
export class Environment extends Class {
  /**
   * Asset administration shell
   */
  assetAdministrationShells: Array<AssetAdministrationShell> | null;

  /**
   * Submodel
   */
  submodels: Array<Submodel> | null;

  /**
   * Concept description
   */
  conceptDescriptions: Array<ConceptDescription> | null;

  /**
   * Yield from {@link assetAdministrationShells} if it is set, or yield nothing.
   */
  *overAssetAdministrationShellsOrEmpty(): IterableIterator<AssetAdministrationShell> {
    if (this.assetAdministrationShells !== null) {
      yield* this.assetAdministrationShells;
    }
    return;
  }

  /**
   * Yield from {@link submodels} if it is set, or yield nothing.
   */
  *overSubmodelsOrEmpty(): IterableIterator<Submodel> {
    if (this.submodels !== null) {
      yield* this.submodels;
    }
    return;
  }

  /**
   * Yield from {@link conceptDescriptions} if it is set, or yield nothing.
   */
  *overConceptDescriptionsOrEmpty(): IterableIterator<ConceptDescription> {
    if (this.conceptDescriptions !== null) {
      yield* this.conceptDescriptions;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    if (this.assetAdministrationShells !== null) {
      yield* this.assetAdministrationShells;
    }

    if (this.submodels !== null) {
      yield* this.submodels;
    }

    if (this.conceptDescriptions !== null) {
      yield* this.conceptDescriptions;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    if (this.assetAdministrationShells !== null) {
      for (const anItem of this.assetAdministrationShells) {
        yield anItem;

        yield* anItem.descend();
      }
    }

    if (this.submodels !== null) {
      for (const anotherItem of this.submodels) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.conceptDescriptions !== null) {
      for (const yetAnotherItem of this.conceptDescriptions) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitEnvironment(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitEnvironmentWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformEnvironment(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformEnvironmentWithContext(this, context);
  }

  constructor(
    assetAdministrationShells: Array<AssetAdministrationShell> | null = null,
    submodels: Array<Submodel> | null = null,
    conceptDescriptions: Array<ConceptDescription> | null = null
  ) {
    super();
    this.assetAdministrationShells = assetAdministrationShells;
    this.submodels = submodels;
    this.conceptDescriptions = conceptDescriptions;
  }
}

/**
 * Data specification content is part of a data specification template and defines
 * which additional attributes shall be added to the element instance that references
 * the data specification template and meta information about the template itself.
 *
 * Constraint `AASc-3a-050`:
 * If the {@link DataSpecificationIec61360} is used
 * for an element, the value of
 * {@link IHasDataSpecification.embeddedDataSpecifications}
 * shall contain the global reference to the IRI of the corresponding
 * data specification template
 * https://admin-shell.io/DataSpecificationTemplates/DataSpecificationIEC61360/3/0
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDataSpecificationContent extends Class {
  // Intentionally empty.
}

/**
 * Embed the content of a data specification.
 */
export class EmbeddedDataSpecification extends Class {
  /**
   * Reference to the data specification
   */
  dataSpecification: Reference;

  /**
   * Actual content of the data specification
   */
  dataSpecificationContent: IDataSpecificationContent;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    yield this.dataSpecification;

    yield this.dataSpecificationContent;
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    yield this.dataSpecification;

    yield* this.dataSpecification.descend();

    yield this.dataSpecificationContent;

    yield* this.dataSpecificationContent.descend();
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitEmbeddedDataSpecification(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitEmbeddedDataSpecificationWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformEmbeddedDataSpecification(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformEmbeddedDataSpecificationWithContext(this, context);
  }

  constructor(
    dataSpecification: Reference,
    dataSpecificationContent: IDataSpecificationContent
  ) {
    super();
    this.dataSpecification = dataSpecification;
    this.dataSpecificationContent = dataSpecificationContent;
  }
}

export enum DataTypeIec61360 {
  /**
   * values containing a calendar date, conformant to ISO 8601:2004 Format yyyy-mm-dd
   * Example from IEC 61360-1:2017: "1999-05-31" is the [DATE] representation of:
   * "31 May 1999".
   */
  Date = 0,
  /**
   * values consisting of sequence of characters but cannot be translated into other
   * languages
   */
  String,
  /**
   * values containing string but shall be represented as different string in different
   * languages
   */
  StringTranslatable,
  /**
   * values containing values that are measure of type INTEGER. In addition such a value
   * comes with a physical unit.
   */
  IntegerMeasure,
  /**
   * values containing values of type INTEGER but are no currencies or measures
   */
  IntegerCount,
  /**
   * values containing values of type INTEGER that are currencies
   */
  IntegerCurrency,
  /**
   * values containing values that are measures of type REAL. In addition such a value
   * comes with a physical unit.
   */
  RealMeasure,
  /**
   * values containing numbers that can be written as a terminating or non-terminating
   * decimal; a rational or irrational number but are no currencies or measures
   */
  RealCount,
  /**
   * values containing values of type REAL that are currencies
   */
  RealCurrency,
  /**
   * values representing truth of logic or Boolean algebra (TRUE, FALSE)
   */
  Boolean,
  /**
   * values containing values of type STRING conformant to Rfc 3987
   *
   * @remarks
   *
   * **Note**:
   * In IEC61360-1 (2017) only URI is supported.
   * An IRI type allows in particular to express an URL or an URI.
   */
  Iri,
  /**
   * values conforming to ISO/IEC 11179 series global identifier sequences
   *
   * @remarks
   *
   * IRDI can be used instead of the more specific data types ICID or ISO29002_IRDI.
   *
   * ICID values are value conformant to an IRDI, where the delimiter between RAI and ID
   * is “#” while the delimiter between DI and VI is confined to “##”
   *
   * ISO29002_IRDI values are values containing a global identifier that identifies an
   * administrated item in a registry. The structure of this identifier complies with
   * identifier syntax defined in ISO/TS 29002-5. The identifier shall fulfil the
   * requirements specified in ISO/TS 29002-5 for an "international registration data
   * identifier" (IRDI).
   */
  Irdi,
  /**
   * values containing values of type rational
   */
  Rational,
  /**
   * values containing values of type rational. In addition such a value comes with a
   * physical unit.
   */
  RationalMeasure,
  /**
   * values containing a time, conformant to ISO 8601:2004 but restricted to what is
   * allowed in the corresponding type in xml.
   *
   * @remarks
   *
   * Format hh:mm (ECLASS)
   *
   * Example from IEC 61360-1:2017: "13:20:00-05:00" is the [TIME] representation of:
   * 1.20 p.m. for Eastern Standard Time, which is 5 hours behind Coordinated
   * Universal Time (UTC).
   */
  Time,
  /**
   * values containing a time, conformant to ISO 8601:2004 but restricted to what is
   * allowed in the corresponding type in xml.
   *
   * @remarks
   *
   * Format yyyy-mm-dd hh:mm (ECLASS)
   */
  Timestamp,
  /**
   * values containing an address to a file. The values are of type URI and can represent
   * an absolute or relative path.
   *
   * @remarks
   *
   * **Note**:
   * IEC61360 does not support the file type.
   */
  File,
  /**
   * Values containing string with any sequence of characters, using the syntax of HTML5
   * (see W3C Recommendation 28:2014)
   */
  Html,
  /**
   * values containing the content of a file. Values may be binaries.
   *
   * @remarks
   *
   * HTML conformant to HTML5 is a special blob.
   *
   * In IEC61360 binary is for a sequence of bits, each bit being represented by “0” and
   * “1” only. A binary is a blob but a blob may also contain other source code.
   */
  Blob
}

/**
 * Iterate over the literals of {@link DataTypeIec61360}.
 *
 * @remark
 * TypeScript does not provide an elegant way to iterate over the literals, so
 * this function helps you avoid common errors and pitfalls.
 *
 * @return iterator over the literals
 */
export function* overDataTypeIec61360(): IterableIterator<DataTypeIec61360> {
  // NOTE (mristin, 2022-12-03):
  // We yield numbers instead of literals to avoid name lookups on platforms
  // which do not provide JIT compilation of hot paths.
  yield <DataTypeIec61360>0; // Date
  yield <DataTypeIec61360>1; // String
  yield <DataTypeIec61360>2; // StringTranslatable
  yield <DataTypeIec61360>3; // IntegerMeasure
  yield <DataTypeIec61360>4; // IntegerCount
  yield <DataTypeIec61360>5; // IntegerCurrency
  yield <DataTypeIec61360>6; // RealMeasure
  yield <DataTypeIec61360>7; // RealCount
  yield <DataTypeIec61360>8; // RealCurrency
  yield <DataTypeIec61360>9; // Boolean
  yield <DataTypeIec61360>10; // Iri
  yield <DataTypeIec61360>11; // Irdi
  yield <DataTypeIec61360>12; // Rational
  yield <DataTypeIec61360>13; // RationalMeasure
  yield <DataTypeIec61360>14; // Time
  yield <DataTypeIec61360>15; // Timestamp
  yield <DataTypeIec61360>16; // File
  yield <DataTypeIec61360>17; // Html
  yield <DataTypeIec61360>18; // Blob
}

/**
 * Value represented by up to four variants of a numeric value in a specific role:
 * `MIN`, `NOM`, `TYP` and `MAX`. True means that the value is available,
 * false means the value is not available.
 *
 * @remarks
 * EXAMPLE from [IEC61360-1]: In the case of having a property which is
 * of the LEVEL_TYPE min/max − expressing a range − only those two values
 * need to be provided.
 *
 * **Note**:
 * This is how AAS deals with the following combinations of level types:
 *
 * * Either all attributes are false. In this case the concept is mapped
 *   to a {@link Property} and level type is ignored.
 * * At most one of the attributes is set to true. In this case
 *   the concept is mapped to a {@link Property}.
 * * Min and max are set to true. In this case the concept is mapped
 *   to a {@link Range}.
 * * More than one attribute is set to true but not min and max only
 *   (see second case). In this case the concept is mapped
 *   to a {@link SubmodelElementCollection} with the corresponding
 *   number of Properties.
 *   Example: If attribute {@link LevelType.min} and {@link LevelType.nom} are set to true
 *   then the concept is mapped to a {@link SubmodelElementCollection}
 *   with two Properties within: min and nom.
 *   The data type of both Properties is the same.
 *
 * **Note**:
 * In the cases 2. and 4. the {@link Property.semanticId} of the Property
 * or Properties within the {@link SubmodelElementCollection} needs to include
 * information about the level type. Otherwise, the semantics is not described
 * in a unique way. Please refer to the specification.
 */
export class LevelType extends Class {
  /**
   * Minimum of the value
   */
  min: boolean;

  /**
   * Nominal value (value as designated)
   */
  nom: boolean;

  /**
   * Value as typically present
   */
  typ: boolean;

  /**
   * Maximum of the value
   */
  max: boolean;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitLevelType(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitLevelTypeWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformLevelType(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformLevelTypeWithContext(this, context);
  }

  constructor(min: boolean, nom: boolean, typ: boolean, max: boolean) {
    super();
    this.min = min;
    this.nom = nom;
    this.typ = typ;
    this.max = max;
  }
}

/**
 * A value reference pair within a value list. Each value has a global unique id
 * defining its semantic.
 */
export class ValueReferencePair extends Class {
  /**
   * The value of the referenced concept definition of the value in {@link valueId}.
   */
  value: string;

  /**
   * Global unique id of the value.
   *
   * @remarks
   * **Note**:
   * It is recommended to use a global reference.
   */
  valueId: Reference;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    yield this.valueId;
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    yield this.valueId;

    yield* this.valueId.descend();
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitValueReferencePair(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitValueReferencePairWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformValueReferencePair(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformValueReferencePairWithContext(this, context);
  }

  constructor(value: string, valueId: Reference) {
    super();
    this.value = value;
    this.valueId = valueId;
  }
}

/**
 * A set of value reference pairs.
 */
export class ValueList extends Class {
  /**
   * A pair of a value together with its global unique id.
   */
  valueReferencePairs: Array<ValueReferencePair>;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    yield* this.valueReferencePairs;
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    for (const anItem of this.valueReferencePairs) {
      yield anItem;

      yield* anItem.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitValueList(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitValueListWithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformValueList(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformValueListWithContext(this, context);
  }

  constructor(valueReferencePairs: Array<ValueReferencePair>) {
    super();
    this.valueReferencePairs = valueReferencePairs;
  }
}

/**
 * String with length 255 maximum and minimum 1 characters and with language tags
 *
 * @remarks
 * **Note**:
 * It is advised to keep the length of the name limited to 35 characters.
 */
export class LangStringPreferredNameTypeIec61360
  extends Class
  implements IAbstractLangString
{
  /**
   * Language tag conforming to BCP 47
   */
  language: string;

  /**
   * Text in the {@link IAbstractLangString.language}
   */
  text: string;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitLangStringPreferredNameTypeIec61360(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitLangStringPreferredNameTypeIec61360WithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformLangStringPreferredNameTypeIec61360(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformLangStringPreferredNameTypeIec61360WithContext(
      this,
      context
    );
  }

  constructor(language: string, text: string) {
    super();
    this.language = language;
    this.text = text;
  }
}

/**
 * String with length 18 maximum and minimum 1 characters and with language tags
 */
export class LangStringShortNameTypeIec61360
  extends Class
  implements IAbstractLangString
{
  /**
   * Language tag conforming to BCP 47
   */
  language: string;

  /**
   * Text in the {@link IAbstractLangString.language}
   */
  text: string;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitLangStringShortNameTypeIec61360(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitLangStringShortNameTypeIec61360WithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformLangStringShortNameTypeIec61360(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformLangStringShortNameTypeIec61360WithContext(
      this,
      context
    );
  }

  constructor(language: string, text: string) {
    super();
    this.language = language;
    this.text = text;
  }
}

/**
 * String with length 1023 maximum and minimum 1 characters and with language tags
 */
export class LangStringDefinitionTypeIec61360
  extends Class
  implements IAbstractLangString
{
  /**
   * Language tag conforming to BCP 47
   */
  language: string;

  /**
   * Text in the {@link IAbstractLangString.language}
   */
  text: string;

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    // No descendable properties
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitLangStringDefinitionTypeIec61360(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitLangStringDefinitionTypeIec61360WithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformLangStringDefinitionTypeIec61360(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformLangStringDefinitionTypeIec61360WithContext(
      this,
      context
    );
  }

  constructor(language: string, text: string) {
    super();
    this.language = language;
    this.text = text;
  }
}

/**
 * Content of data specification template for concept descriptions for properties,
 * values and value lists conformant to IEC 61360.
 *
 * @remarks
 * **Note**:
 * IEC61360 requires also a globally unique identifier for a concept
 * description. This ID is not part of the data specification template.
 * Instead the {@link ConceptDescription.id} as inherited via
 * {@link IIdentifiable} is used. Same holds for administrative
 * information like the version and revision.
 *
 * **Note**:
 * {@link ConceptDescription.idShort} and {@link DataSpecificationIec61360.shortName} are very
 * similar. However, in this case the decision was to add
 * {@link DataSpecificationIec61360.shortName} explicitly to the data specification. Same holds for
 * {@link ConceptDescription.displayName} and
 * {@link DataSpecificationIec61360.preferredName}. Same holds for
 * {@link ConceptDescription.description} and {@link DataSpecificationIec61360.definition}.
 *
 * Constraint `AASc-3a-010`:
 * If {@link DataSpecificationIec61360.value} is not empty then {@link DataSpecificationIec61360.valueList} shall be empty
 * and vice versa.
 *
 * **Note**:
 * It is also possible that both {@link DataSpecificationIec61360.value} and {@link DataSpecificationIec61360.valueList} are
 * empty. This is the case for concept descriptions that define the semantics
 * of a property but do not have an enumeration ({@link DataSpecificationIec61360.valueList}) as
 * data type.
 *
 * **Note**:
 * Although it is possible to define a {@link ConceptDescription} for a
 * :attr:´value_list`,
 * it is not possible to reuse this {@link DataSpecificationIec61360.valueList}.
 * It is only possible to directly add a {@link DataSpecificationIec61360.valueList} as data type
 * to a specific semantic definition of a property.
 *
 * Constraint `AASc-3a-009`:
 * If {@link DataSpecificationIec61360.dataType} one of:
 * {@link DataTypeIec61360.IntegerMeasure},
 * {@link DataTypeIec61360.RealMeasure},
 * {@link DataTypeIec61360.RationalMeasure},
 * {@link DataTypeIec61360.IntegerCurrency},
 * {@link DataTypeIec61360.RealCurrency}, then {@link DataSpecificationIec61360.unit} or
 * {@link DataSpecificationIec61360.unitId} shall be defined.
 */
export class DataSpecificationIec61360
  extends Class
  implements IDataSpecificationContent
{
  /**
   * Preferred name
   *
   * @remarks
   * **Note**:
   * It is advised to keep the length of the name limited to 35 characters.
   *
   * Constraint `AASc-3a-002`:
   * {@link preferredName} shall be provided at least in English.
   */
  preferredName: Array<LangStringPreferredNameTypeIec61360>;

  /**
   * Short name
   */
  shortName: Array<LangStringShortNameTypeIec61360> | null;

  /**
   * Unit
   */
  unit: string | null;

  /**
   * Unique unit id
   *
   * @remarks
   * {@link unit} and {@link unitId} need to be consistent if both attributes
   * are set
   *
   * **Note**:
   * It is recommended to use an external reference ID.
   */
  unitId: Reference | null;

  /**
   * Source of definition
   */
  sourceOfDefinition: string | null;

  /**
   * Symbol
   */
  symbol: string | null;

  /**
   * Data Type
   */
  dataType: DataTypeIec61360 | null;

  /**
   * Definition in different languages
   */
  definition: Array<LangStringDefinitionTypeIec61360> | null;

  /**
   * Value Format
   *
   * @remarks
   * **Note**:
   * The value format is based on ISO 13584-42 and IEC 61360-2.
   */
  valueFormat: string | null;

  /**
   * List of allowed values
   */
  valueList: ValueList | null;

  /**
   * Value
   */
  value: string | null;

  /**
   * Set of levels.
   */
  levelType: LevelType | null;

  /**
   * Yield from {@link shortName} if it is set, or yield nothing.
   */
  *overShortNameOrEmpty(): IterableIterator<LangStringShortNameTypeIec61360> {
    if (this.shortName !== null) {
      yield* this.shortName;
    }
    return;
  }

  /**
   * Yield from {@link definition} if it is set, or yield nothing.
   */
  *overDefinitionOrEmpty(): IterableIterator<LangStringDefinitionTypeIec61360> {
    if (this.definition !== null) {
      yield* this.definition;
    }
    return;
  }

  /**
   * Iterate over the instances referenced from this instance.
   *
   * We do not recurse into the referenced instances.
   *
   * @returns Iterator over the referenced instances
   */
  *descendOnce(): IterableIterator<Class> {
    yield* this.preferredName;

    if (this.shortName !== null) {
      yield* this.shortName;
    }

    if (this.unitId !== null) {
      yield this.unitId;
    }

    if (this.definition !== null) {
      yield* this.definition;
    }

    if (this.valueList !== null) {
      yield this.valueList;
    }

    if (this.levelType !== null) {
      yield this.levelType;
    }
  }

  /**
   * Iterate recursively over the instances referenced from this instance.
   *
   * @returns Iterator over the referenced instances
   */
  *descend(): IterableIterator<Class> {
    for (const anItem of this.preferredName) {
      yield anItem;

      yield* anItem.descend();
    }

    if (this.shortName !== null) {
      for (const anotherItem of this.shortName) {
        yield anotherItem;

        yield* anotherItem.descend();
      }
    }

    if (this.unitId !== null) {
      yield this.unitId;

      yield* this.unitId.descend();
    }

    if (this.definition !== null) {
      for (const yetAnotherItem of this.definition) {
        yield yetAnotherItem;

        yield* yetAnotherItem.descend();
      }
    }

    if (this.valueList !== null) {
      yield this.valueList;

      yield* this.valueList.descend();
    }

    if (this.levelType !== null) {
      yield this.levelType;

      yield* this.levelType.descend();
    }
  }

  /**
   * Dispatch `visitor` on this instance.
   *
   * @param visitor - to visit this instance
   */
  accept(visitor: AbstractVisitor): void {
    visitor.visitDataSpecificationIec61360(this);
  }

  /**
   * Dispatch `visitor` with `context` on this instance.
   *
   * @param visitor - to visit this instance
   * @param context - to be passed along to the dispatched visitor method
   * @typeParam ContextT - type of the context
   */
  acceptWithContext<ContextT>(
    visitor: AbstractVisitorWithContext<ContextT>,
    context: ContextT
  ) {
    visitor.visitDataSpecificationIec61360WithContext(this, context);
  }

  /**
   * Dispatch the `transformer` on this instance.
   *
   * @param transformer - to transform this instance
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   */
  transform<T>(transformer: AbstractTransformer<T>): T {
    return transformer.transformDataSpecificationIec61360(this);
  }

  /**
   * Dispatch the `transformer` on this instance in `context`.
   *
   * @param transformer - to transform this instance
   * @param context - to be passed along to the `transformer`
   * @returns transformation of this instance
   * @paramType T - type of the transformation result
   * @paramType ContextT - type of the transformation context
   */
  transformWithContext<ContextT, T>(
    transformer: AbstractTransformerWithContext<ContextT, T>,
    context: ContextT
  ): T {
    return transformer.transformDataSpecificationIec61360WithContext(this, context);
  }

  constructor(
    preferredName: Array<LangStringPreferredNameTypeIec61360>,
    shortName: Array<LangStringShortNameTypeIec61360> | null = null,
    unit: string | null = null,
    unitId: Reference | null = null,
    sourceOfDefinition: string | null = null,
    symbol: string | null = null,
    dataType: DataTypeIec61360 | null = null,
    definition: Array<LangStringDefinitionTypeIec61360> | null = null,
    valueFormat: string | null = null,
    valueList: ValueList | null = null,
    value: string | null = null,
    levelType: LevelType | null = null
  ) {
    super();
    this.preferredName = preferredName;
    this.shortName = shortName;
    this.unit = unit;
    this.unitId = unitId;
    this.sourceOfDefinition = sourceOfDefinition;
    this.symbol = symbol;
    this.dataType = dataType;
    this.definition = definition;
    this.valueFormat = valueFormat;
    this.valueList = valueList;
    this.value = value;
    this.levelType = levelType;
  }
}

/**
 * Visit the instances of the model.
 */
export abstract class AbstractVisitor {
  /**
   * Double-dispatch on `that`.
   */
  visit(that: Class): void {
    that.accept(this);
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitExtension(that: Extension): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitAdministrativeInformation(that: AdministrativeInformation): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitQualifier(that: Qualifier): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitAssetAdministrationShell(that: AssetAdministrationShell): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitAssetInformation(that: AssetInformation): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitResource(that: Resource): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitSpecificAssetId(that: SpecificAssetId): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitSubmodel(that: Submodel): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitRelationshipElement(that: RelationshipElement): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitSubmodelElementList(that: SubmodelElementList): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitSubmodelElementCollection(that: SubmodelElementCollection): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitProperty(that: Property): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitMultiLanguageProperty(that: MultiLanguageProperty): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitRange(that: Range): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitReferenceElement(that: ReferenceElement): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitBlob(that: Blob): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitFile(that: File): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitAnnotatedRelationshipElement(that: AnnotatedRelationshipElement): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitEntity(that: Entity): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitEventPayload(that: EventPayload): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitBasicEventElement(that: BasicEventElement): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitOperation(that: Operation): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitOperationVariable(that: OperationVariable): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitCapability(that: Capability): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitConceptDescription(that: ConceptDescription): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitReference(that: Reference): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitKey(that: Key): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitLangStringNameType(that: LangStringNameType): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitLangStringTextType(that: LangStringTextType): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitEnvironment(that: Environment): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitEmbeddedDataSpecification(that: EmbeddedDataSpecification): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitLevelType(that: LevelType): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitValueReferencePair(that: ValueReferencePair): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitValueList(that: ValueList): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): void;

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  abstract visitDataSpecificationIec61360(that: DataSpecificationIec61360): void;
}

/**
 * Visit the instances of the model with context.
 *
 * @typeParam ContextT - type of the visitation context
 */
export abstract class AbstractVisitorWithContext<ContextT> {
  /**
   * Double-dispatch on `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitWithContext(that: Class, context: ContextT): void {
    that.acceptWithContext(this, context);
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitExtensionWithContext(that: Extension, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitAdministrativeInformationWithContext(
    that: AdministrativeInformation,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitQualifierWithContext(that: Qualifier, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitAssetAdministrationShellWithContext(
    that: AssetAdministrationShell,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitAssetInformationWithContext(
    that: AssetInformation,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitResourceWithContext(that: Resource, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitSpecificAssetIdWithContext(
    that: SpecificAssetId,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitSubmodelWithContext(that: Submodel, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitRelationshipElementWithContext(
    that: RelationshipElement,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitSubmodelElementListWithContext(
    that: SubmodelElementList,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitSubmodelElementCollectionWithContext(
    that: SubmodelElementCollection,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitPropertyWithContext(that: Property, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitMultiLanguagePropertyWithContext(
    that: MultiLanguageProperty,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitRangeWithContext(that: Range, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitReferenceElementWithContext(
    that: ReferenceElement,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitBlobWithContext(that: Blob, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitFileWithContext(that: File, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitAnnotatedRelationshipElementWithContext(
    that: AnnotatedRelationshipElement,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitEntityWithContext(that: Entity, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitEventPayloadWithContext(that: EventPayload, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitBasicEventElementWithContext(
    that: BasicEventElement,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitOperationWithContext(that: Operation, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitOperationVariableWithContext(
    that: OperationVariable,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitCapabilityWithContext(that: Capability, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitConceptDescriptionWithContext(
    that: ConceptDescription,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitReferenceWithContext(that: Reference, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitKeyWithContext(that: Key, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitLangStringNameTypeWithContext(
    that: LangStringNameType,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitLangStringTextTypeWithContext(
    that: LangStringTextType,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitEnvironmentWithContext(that: Environment, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitEmbeddedDataSpecificationWithContext(
    that: EmbeddedDataSpecification,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitLevelTypeWithContext(that: LevelType, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitValueReferencePairWithContext(
    that: ValueReferencePair,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitValueListWithContext(that: ValueList, context: ContextT): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitLangStringPreferredNameTypeIec61360WithContext(
    that: LangStringPreferredNameTypeIec61360,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitLangStringShortNameTypeIec61360WithContext(
    that: LangStringShortNameTypeIec61360,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitLangStringDefinitionTypeIec61360WithContext(
    that: LangStringDefinitionTypeIec61360,
    context: ContextT
  ): void;

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  abstract visitDataSpecificationIec61360WithContext(
    that: DataSpecificationIec61360,
    context: ContextT
  ): void;
}

/**
 * Visit the instances of the model without action.
 *
 * @remarks
 * This visitor is not meant to be directly used. Instead, you usually
 * inherit from it, and implement only the relevant visit methods.
 */
export class PassThroughVisitor extends AbstractVisitor {
  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitExtension(that: Extension): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitAdministrativeInformation(that: AdministrativeInformation): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitQualifier(that: Qualifier): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitAssetAdministrationShell(that: AssetAdministrationShell): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitAssetInformation(that: AssetInformation): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitResource(that: Resource): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitSpecificAssetId(that: SpecificAssetId): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitSubmodel(that: Submodel): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitRelationshipElement(that: RelationshipElement): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitSubmodelElementList(that: SubmodelElementList): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitSubmodelElementCollection(that: SubmodelElementCollection): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitProperty(that: Property): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitMultiLanguageProperty(that: MultiLanguageProperty): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitRange(that: Range): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitReferenceElement(that: ReferenceElement): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitBlob(that: Blob): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitFile(that: File): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitAnnotatedRelationshipElement(that: AnnotatedRelationshipElement): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitEntity(that: Entity): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitEventPayload(that: EventPayload): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitBasicEventElement(that: BasicEventElement): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitOperation(that: Operation): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitOperationVariable(that: OperationVariable): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitCapability(that: Capability): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitConceptDescription(that: ConceptDescription): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitReference(that: Reference): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitKey(that: Key): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitLangStringNameType(that: LangStringNameType): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitLangStringTextType(that: LangStringTextType): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitEnvironment(that: Environment): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitEmbeddedDataSpecification(that: EmbeddedDataSpecification): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitLevelType(that: LevelType): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitValueReferencePair(that: ValueReferencePair): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitValueList(that: ValueList): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitLangStringShortNameTypeIec61360(that: LangStringShortNameTypeIec61360): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitLangStringDefinitionTypeIec61360(that: LangStringDefinitionTypeIec61360): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }

  /**
   * Visit `that`.
   *
   * @param that - instance to be visited
   */
  visitDataSpecificationIec61360(that: DataSpecificationIec61360): void {
    for (const another of that.descendOnce()) {
      this.visit(another);
    }
  }
}

/**
 * Visit the instances of the model without action and in context.
 *
 * @remarks
 * This visitor is not meant to be directly used. Instead, you usually
 * inherit from it, and implement only the relevant visit methods.
 */
export class PassThroughVisitorWithContext<
  ContextT
> extends AbstractVisitorWithContext<ContextT> {
  /**
   * Double-dispatch on `that` in `context`.
   */
  visitWithContext(that: Class, context: ContextT): void {
    that.acceptWithContext(this, context);
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitExtensionWithContext(that: Extension, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitAdministrativeInformationWithContext(
    that: AdministrativeInformation,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitQualifierWithContext(that: Qualifier, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitAssetAdministrationShellWithContext(
    that: AssetAdministrationShell,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitAssetInformationWithContext(that: AssetInformation, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitResourceWithContext(that: Resource, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitSpecificAssetIdWithContext(that: SpecificAssetId, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitSubmodelWithContext(that: Submodel, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitRelationshipElementWithContext(
    that: RelationshipElement,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitSubmodelElementListWithContext(
    that: SubmodelElementList,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitSubmodelElementCollectionWithContext(
    that: SubmodelElementCollection,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitPropertyWithContext(that: Property, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitMultiLanguagePropertyWithContext(
    that: MultiLanguageProperty,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitRangeWithContext(that: Range, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitReferenceElementWithContext(that: ReferenceElement, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitBlobWithContext(that: Blob, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitFileWithContext(that: File, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitAnnotatedRelationshipElementWithContext(
    that: AnnotatedRelationshipElement,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitEntityWithContext(that: Entity, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitEventPayloadWithContext(that: EventPayload, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitBasicEventElementWithContext(that: BasicEventElement, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitOperationWithContext(that: Operation, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitOperationVariableWithContext(that: OperationVariable, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitCapabilityWithContext(that: Capability, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitConceptDescriptionWithContext(
    that: ConceptDescription,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitReferenceWithContext(that: Reference, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitKeyWithContext(that: Key, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitLangStringNameTypeWithContext(
    that: LangStringNameType,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitLangStringTextTypeWithContext(
    that: LangStringTextType,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitEnvironmentWithContext(that: Environment, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitEmbeddedDataSpecificationWithContext(
    that: EmbeddedDataSpecification,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitLevelTypeWithContext(that: LevelType, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitValueReferencePairWithContext(
    that: ValueReferencePair,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitValueListWithContext(that: ValueList, context: ContextT): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitLangStringPreferredNameTypeIec61360WithContext(
    that: LangStringPreferredNameTypeIec61360,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitLangStringShortNameTypeIec61360WithContext(
    that: LangStringShortNameTypeIec61360,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitLangStringDefinitionTypeIec61360WithContext(
    that: LangStringDefinitionTypeIec61360,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }

  /**
   * Visit `that` in `context`.
   *
   * @param that - instance to be visited
   * @param context - of the visitation
   */
  visitDataSpecificationIec61360WithContext(
    that: DataSpecificationIec61360,
    context: ContextT
  ): void {
    for (const another of that.descendOnce()) {
      this.visitWithContext(another, context);
    }
  }
}

/**
 * Transform the instance of the model.
 *
 * @typeParam T - type of the transformation result
 */
export abstract class AbstractTransformer<T> {
  /**
   * Double-dispatch on `that`.
   */
  transform(that: Class): T {
    return that.transform(this);
  }

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformExtension(that: Extension): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformAdministrativeInformation(that: AdministrativeInformation): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformQualifier(that: Qualifier): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformAssetAdministrationShell(that: AssetAdministrationShell): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformAssetInformation(that: AssetInformation): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformResource(that: Resource): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformSpecificAssetId(that: SpecificAssetId): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformSubmodel(that: Submodel): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformRelationshipElement(that: RelationshipElement): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformSubmodelElementList(that: SubmodelElementList): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformSubmodelElementCollection(that: SubmodelElementCollection): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformProperty(that: Property): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformMultiLanguageProperty(that: MultiLanguageProperty): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformRange(that: Range): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformReferenceElement(that: ReferenceElement): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformBlob(that: Blob): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformFile(that: File): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformAnnotatedRelationshipElement(that: AnnotatedRelationshipElement): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformEntity(that: Entity): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformEventPayload(that: EventPayload): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformBasicEventElement(that: BasicEventElement): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformOperation(that: Operation): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformOperationVariable(that: OperationVariable): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformCapability(that: Capability): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformConceptDescription(that: ConceptDescription): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformReference(that: Reference): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformKey(that: Key): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformLangStringNameType(that: LangStringNameType): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformLangStringTextType(that: LangStringTextType): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformEnvironment(that: Environment): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformEmbeddedDataSpecification(that: EmbeddedDataSpecification): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformLevelType(that: LevelType): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformValueReferencePair(that: ValueReferencePair): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformValueList(that: ValueList): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): T;

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  abstract transformDataSpecificationIec61360(that: DataSpecificationIec61360): T;
}

/**
 * Transform the instances of the model in context.
 *
 * @typeParam ContextT - type of the transformation context
 * @typeParam T - type of the transformation result
 */
export abstract class AbstractTransformerWithContext<ContextT, T> {
  /**
   * Double-dispatch on `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  transformWithContext(that: Class, context: ContextT): T {
    return that.transformWithContext(this, context);
  }

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformExtensionWithContext(that: Extension, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformAdministrativeInformationWithContext(
    that: AdministrativeInformation,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformQualifierWithContext(that: Qualifier, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformAssetAdministrationShellWithContext(
    that: AssetAdministrationShell,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformAssetInformationWithContext(
    that: AssetInformation,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformResourceWithContext(that: Resource, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformSpecificAssetIdWithContext(
    that: SpecificAssetId,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformSubmodelWithContext(that: Submodel, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformRelationshipElementWithContext(
    that: RelationshipElement,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformSubmodelElementListWithContext(
    that: SubmodelElementList,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformSubmodelElementCollectionWithContext(
    that: SubmodelElementCollection,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformPropertyWithContext(that: Property, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformMultiLanguagePropertyWithContext(
    that: MultiLanguageProperty,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformRangeWithContext(that: Range, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformReferenceElementWithContext(
    that: ReferenceElement,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformBlobWithContext(that: Blob, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformFileWithContext(that: File, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformAnnotatedRelationshipElementWithContext(
    that: AnnotatedRelationshipElement,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformEntityWithContext(that: Entity, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformEventPayloadWithContext(that: EventPayload, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformBasicEventElementWithContext(
    that: BasicEventElement,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformOperationWithContext(that: Operation, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformOperationVariableWithContext(
    that: OperationVariable,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformCapabilityWithContext(that: Capability, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformConceptDescriptionWithContext(
    that: ConceptDescription,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformReferenceWithContext(that: Reference, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformKeyWithContext(that: Key, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformLangStringNameTypeWithContext(
    that: LangStringNameType,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformLangStringTextTypeWithContext(
    that: LangStringTextType,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformEnvironmentWithContext(that: Environment, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformEmbeddedDataSpecificationWithContext(
    that: EmbeddedDataSpecification,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformLevelTypeWithContext(that: LevelType, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformValueReferencePairWithContext(
    that: ValueReferencePair,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformValueListWithContext(that: ValueList, context: ContextT): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformLangStringPreferredNameTypeIec61360WithContext(
    that: LangStringPreferredNameTypeIec61360,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformLangStringShortNameTypeIec61360WithContext(
    that: LangStringShortNameTypeIec61360,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformLangStringDefinitionTypeIec61360WithContext(
    that: LangStringDefinitionTypeIec61360,
    context: ContextT
  ): T;

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the transformation
   * @returns transformed `that`
   */
  abstract transformDataSpecificationIec61360WithContext(
    that: DataSpecificationIec61360,
    context: ContextT
  ): T;
}

/**
 * Transform the instances of the model.
 *
 * @remarks
 * If you do not override the transformation methods, they simply
 * return {@link defaultResult}.
 *
 * @typeParam T - type of the transformation result
 */
export class TransformerWithDefault<T> extends AbstractTransformer<T> {
  /**
   * Default value which is returned if no override of the transformation
   */
  defaultResult: T;

  /**
   * Initialize with the given `default` value.
   *
   * @param defaultResult - returned if no override of the transformation
   */
  constructor(defaultResult: T) {
    super();
    this.defaultResult = defaultResult;
  }

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(that: AdministrativeInformation): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(that: AssetAdministrationShell): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(that: RelationshipElement): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(that: SubmodelElementCollection): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(that: MultiLanguageProperty): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(that: AnnotatedRelationshipElement): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(that: BasicEventElement): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(that: EmbeddedDataSpecification): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(that: LangStringShortNameTypeIec61360): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(that: LangStringDefinitionTypeIec61360): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that`.
   *
   * @param that - instance to be transformed
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(that: DataSpecificationIec61360): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

/**
 * Transform the instances of the model in context.
 *
 * @remarks
 * If you do not override the transformation methods, they simply
 * return {@link defaultResult}.
 *
 * @typeParam ContextT - type of the visitation context
 * @typeParam T - type of the transformation result
 */
export class TransformerWithDefaultAndContext<
  ContextT,
  T
> extends AbstractTransformerWithContext<ContextT, T> {
  /**
   * Default value which is returned if no override of the transformation
   */
  defaultResult: T;

  /**
   * Initialize with the given `default` value.
   *
   * @param defaultResult - returned if no override of the transformation
   */
  constructor(defaultResult: T) {
    super();
    this.defaultResult = defaultResult;
  }

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtensionWithContext(that: Extension, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformationWithContext(
    that: AdministrativeInformation,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifierWithContext(that: Qualifier, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShellWithContext(
    that: AssetAdministrationShell,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformationWithContext(that: AssetInformation, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResourceWithContext(that: Resource, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetIdWithContext(that: SpecificAssetId, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelWithContext(that: Submodel, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElementWithContext(
    that: RelationshipElement,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementListWithContext(
    that: SubmodelElementList,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollectionWithContext(
    that: SubmodelElementCollection,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformPropertyWithContext(that: Property, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguagePropertyWithContext(
    that: MultiLanguageProperty,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRangeWithContext(that: Range, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElementWithContext(that: ReferenceElement, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlobWithContext(that: Blob, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFileWithContext(that: File, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElementWithContext(
    that: AnnotatedRelationshipElement,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntityWithContext(that: Entity, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayloadWithContext(that: EventPayload, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElementWithContext(that: BasicEventElement, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationWithContext(that: Operation, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariableWithContext(that: OperationVariable, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapabilityWithContext(that: Capability, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescriptionWithContext(
    that: ConceptDescription,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceWithContext(that: Reference, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKeyWithContext(that: Key, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameTypeWithContext(
    that: LangStringNameType,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextTypeWithContext(
    that: LangStringTextType,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironmentWithContext(that: Environment, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecificationWithContext(
    that: EmbeddedDataSpecification,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelTypeWithContext(that: LevelType, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePairWithContext(
    that: ValueReferencePair,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueListWithContext(that: ValueList, context: ContextT): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360WithContext(
    that: LangStringPreferredNameTypeIec61360,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360WithContext(
    that: LangStringShortNameTypeIec61360,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360WithContext(
    that: LangStringDefinitionTypeIec61360,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Transform `that` in `context`.
   *
   * @param that - instance to be transformed
   * @param context - of the visitation
   * @returns transformed `that`
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360WithContext(
    that: DataSpecificationIec61360,
    context: ContextT
  ): T {
    return this.defaultResult;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

/**
 * Try to cast an instance of the model to {@link IHasSemantics}.
 */
class AsHasSemanticsTransformer extends AbstractTransformer<IHasSemantics | null> {
  transformExtension(that: Extension): IHasSemantics | null {
    return that as IHasSemantics;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformQualifier(that: Qualifier): IHasSemantics | null {
    return that as IHasSemantics;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSpecificAssetId(that: SpecificAssetId): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformSubmodel(that: Submodel): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformRelationshipElement(that: RelationshipElement): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformSubmodelElementList(that: SubmodelElementList): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformProperty(that: Property): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformMultiLanguageProperty(that: MultiLanguageProperty): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformRange(that: Range): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformReferenceElement(that: ReferenceElement): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformBlob(that: Blob): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformFile(that: File): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformEntity(that: Entity): IHasSemantics | null {
    return that as IHasSemantics;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): IHasSemantics | null {
    return that as IHasSemantics;
  }

  transformOperation(that: Operation): IHasSemantics | null {
    return that as IHasSemantics;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformCapability(that: Capability): IHasSemantics | null {
    return that as IHasSemantics;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IHasSemantics | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_HAS_SEMANTICS_TRANSFORMER = new AsHasSemanticsTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IHasSemantics}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asHasSemantics(that: Class): IHasSemantics | null {
  return AS_HAS_SEMANTICS_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isHasSemantics(that: Class): that is IHasSemantics {
  return asHasSemantics(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link Extension}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asExtension(that: Class): Extension | null {
  return that instanceof Extension ? <Extension>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isExtension(that: Class): that is Extension {
  return that instanceof Extension;
}

/**
 * Try to cast an instance of the model to {@link IHasExtensions}.
 */
class AsHasExtensionsTransformer extends AbstractTransformer<IHasExtensions | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IHasExtensions | null {
    return that as IHasExtensions;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSubmodel(that: Submodel): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformRelationshipElement(that: RelationshipElement): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformSubmodelElementList(that: SubmodelElementList): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformProperty(that: Property): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformMultiLanguageProperty(that: MultiLanguageProperty): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformRange(that: Range): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformReferenceElement(that: ReferenceElement): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformBlob(that: Blob): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformFile(that: File): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformEntity(that: Entity): IHasExtensions | null {
    return that as IHasExtensions;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformOperation(that: Operation): IHasExtensions | null {
    return that as IHasExtensions;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformCapability(that: Capability): IHasExtensions | null {
    return that as IHasExtensions;
  }

  transformConceptDescription(that: ConceptDescription): IHasExtensions | null {
    return that as IHasExtensions;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IHasExtensions | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_HAS_EXTENSIONS_TRANSFORMER = new AsHasExtensionsTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IHasExtensions}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asHasExtensions(that: Class): IHasExtensions | null {
  return AS_HAS_EXTENSIONS_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isHasExtensions(that: Class): that is IHasExtensions {
  return asHasExtensions(that) !== null;
}

/**
 * Try to cast an instance of the model to {@link IReferable}.
 */
class AsReferableTransformer extends AbstractTransformer<IReferable | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformAssetAdministrationShell(that: AssetAdministrationShell): IReferable | null {
    return that as IReferable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSubmodel(that: Submodel): IReferable | null {
    return that as IReferable;
  }

  transformRelationshipElement(that: RelationshipElement): IReferable | null {
    return that as IReferable;
  }

  transformSubmodelElementList(that: SubmodelElementList): IReferable | null {
    return that as IReferable;
  }

  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IReferable | null {
    return that as IReferable;
  }

  transformProperty(that: Property): IReferable | null {
    return that as IReferable;
  }

  transformMultiLanguageProperty(that: MultiLanguageProperty): IReferable | null {
    return that as IReferable;
  }

  transformRange(that: Range): IReferable | null {
    return that as IReferable;
  }

  transformReferenceElement(that: ReferenceElement): IReferable | null {
    return that as IReferable;
  }

  transformBlob(that: Blob): IReferable | null {
    return that as IReferable;
  }

  transformFile(that: File): IReferable | null {
    return that as IReferable;
  }

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IReferable | null {
    return that as IReferable;
  }

  transformEntity(that: Entity): IReferable | null {
    return that as IReferable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): IReferable | null {
    return that as IReferable;
  }

  transformOperation(that: Operation): IReferable | null {
    return that as IReferable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformCapability(that: Capability): IReferable | null {
    return that as IReferable;
  }

  transformConceptDescription(that: ConceptDescription): IReferable | null {
    return that as IReferable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IReferable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_REFERABLE_TRANSFORMER = new AsReferableTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IReferable}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asReferable(that: Class): IReferable | null {
  return AS_REFERABLE_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isReferable(that: Class): that is IReferable {
  return asReferable(that) !== null;
}

/**
 * Try to cast an instance of the model to {@link IIdentifiable}.
 */
class AsIdentifiableTransformer extends AbstractTransformer<IIdentifiable | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IIdentifiable | null {
    return that as IIdentifiable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSubmodel(that: Submodel): IIdentifiable | null {
    return that as IIdentifiable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(that: RelationshipElement): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(that: MultiLanguageProperty): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(that: BasicEventElement): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformConceptDescription(that: ConceptDescription): IIdentifiable | null {
    return that as IIdentifiable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IIdentifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_IDENTIFIABLE_TRANSFORMER = new AsIdentifiableTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IIdentifiable}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asIdentifiable(that: Class): IIdentifiable | null {
  return AS_IDENTIFIABLE_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isIdentifiable(that: Class): that is IIdentifiable {
  return asIdentifiable(that) !== null;
}

/**
 * Try to cast an instance of the model to {@link IHasKind}.
 */
class AsHasKindTransformer extends AbstractTransformer<IHasKind | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(that: AdministrativeInformation): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(that: AssetAdministrationShell): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSubmodel(that: Submodel): IHasKind | null {
    return that as IHasKind;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(that: RelationshipElement): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(that: SubmodelElementCollection): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(that: MultiLanguageProperty): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(that: BasicEventElement): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(that: EmbeddedDataSpecification): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(that: DataSpecificationIec61360): IHasKind | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_HAS_KIND_TRANSFORMER = new AsHasKindTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IHasKind}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asHasKind(that: Class): IHasKind | null {
  return AS_HAS_KIND_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isHasKind(that: Class): that is IHasKind {
  return asHasKind(that) !== null;
}

/**
 * Try to cast an instance of the model to {@link IHasDataSpecification}.
 */
class AsHasDataSpecificationTransformer extends AbstractTransformer<IHasDataSpecification | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSubmodel(that: Submodel): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformRelationshipElement(
    that: RelationshipElement
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformSubmodelElementList(
    that: SubmodelElementList
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformProperty(that: Property): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformMultiLanguageProperty(
    that: MultiLanguageProperty
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformRange(that: Range): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformReferenceElement(that: ReferenceElement): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformBlob(that: Blob): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformFile(that: File): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformEntity(that: Entity): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformOperation(that: Operation): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformCapability(that: Capability): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  transformConceptDescription(that: ConceptDescription): IHasDataSpecification | null {
    return that as IHasDataSpecification;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IHasDataSpecification | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_HAS_DATA_SPECIFICATION_TRANSFORMER = new AsHasDataSpecificationTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IHasDataSpecification}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asHasDataSpecification(that: Class): IHasDataSpecification | null {
  return AS_HAS_DATA_SPECIFICATION_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isHasDataSpecification(that: Class): that is IHasDataSpecification {
  return asHasDataSpecification(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link AdministrativeInformation}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asAdministrativeInformation(
  that: Class
): AdministrativeInformation | null {
  return that instanceof AdministrativeInformation
    ? <AdministrativeInformation>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isAdministrativeInformation(
  that: Class
): that is AdministrativeInformation {
  return that instanceof AdministrativeInformation;
}

/**
 * Try to cast an instance of the model to {@link IQualifiable}.
 */
class AsQualifiableTransformer extends AbstractTransformer<IQualifiable | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformSubmodel(that: Submodel): IQualifiable | null {
    return that as IQualifiable;
  }

  transformRelationshipElement(that: RelationshipElement): IQualifiable | null {
    return that as IQualifiable;
  }

  transformSubmodelElementList(that: SubmodelElementList): IQualifiable | null {
    return that as IQualifiable;
  }

  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IQualifiable | null {
    return that as IQualifiable;
  }

  transformProperty(that: Property): IQualifiable | null {
    return that as IQualifiable;
  }

  transformMultiLanguageProperty(that: MultiLanguageProperty): IQualifiable | null {
    return that as IQualifiable;
  }

  transformRange(that: Range): IQualifiable | null {
    return that as IQualifiable;
  }

  transformReferenceElement(that: ReferenceElement): IQualifiable | null {
    return that as IQualifiable;
  }

  transformBlob(that: Blob): IQualifiable | null {
    return that as IQualifiable;
  }

  transformFile(that: File): IQualifiable | null {
    return that as IQualifiable;
  }

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IQualifiable | null {
    return that as IQualifiable;
  }

  transformEntity(that: Entity): IQualifiable | null {
    return that as IQualifiable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): IQualifiable | null {
    return that as IQualifiable;
  }

  transformOperation(that: Operation): IQualifiable | null {
    return that as IQualifiable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformCapability(that: Capability): IQualifiable | null {
    return that as IQualifiable;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IQualifiable | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_QUALIFIABLE_TRANSFORMER = new AsQualifiableTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IQualifiable}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asQualifiable(that: Class): IQualifiable | null {
  return AS_QUALIFIABLE_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isQualifiable(that: Class): that is IQualifiable {
  return asQualifiable(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link Qualifier}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asQualifier(that: Class): Qualifier | null {
  return that instanceof Qualifier ? <Qualifier>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isQualifier(that: Class): that is Qualifier {
  return that instanceof Qualifier;
}

/**
 * Try to cast `that` instance to
 * the class {@link AssetAdministrationShell}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asAssetAdministrationShell(
  that: Class
): AssetAdministrationShell | null {
  return that instanceof AssetAdministrationShell
    ? <AssetAdministrationShell>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isAssetAdministrationShell(
  that: Class
): that is AssetAdministrationShell {
  return that instanceof AssetAdministrationShell;
}

/**
 * Try to cast `that` instance to
 * the class {@link AssetInformation}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asAssetInformation(that: Class): AssetInformation | null {
  return that instanceof AssetInformation ? <AssetInformation>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isAssetInformation(that: Class): that is AssetInformation {
  return that instanceof AssetInformation;
}

/**
 * Try to cast `that` instance to
 * the class {@link Resource}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asResource(that: Class): Resource | null {
  return that instanceof Resource ? <Resource>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isResource(that: Class): that is Resource {
  return that instanceof Resource;
}

/**
 * Try to cast `that` instance to
 * the class {@link SpecificAssetId}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asSpecificAssetId(that: Class): SpecificAssetId | null {
  return that instanceof SpecificAssetId ? <SpecificAssetId>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isSpecificAssetId(that: Class): that is SpecificAssetId {
  return that instanceof SpecificAssetId;
}

/**
 * Try to cast `that` instance to
 * the class {@link Submodel}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asSubmodel(that: Class): Submodel | null {
  return that instanceof Submodel ? <Submodel>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isSubmodel(that: Class): that is Submodel {
  return that instanceof Submodel;
}

/**
 * Try to cast an instance of the model to {@link ISubmodelElement}.
 */
class AsSubmodelElementTransformer extends AbstractTransformer<ISubmodelElement | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformRelationshipElement(that: RelationshipElement): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformSubmodelElementList(that: SubmodelElementList): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformProperty(that: Property): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformMultiLanguageProperty(that: MultiLanguageProperty): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformRange(that: Range): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformReferenceElement(that: ReferenceElement): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformBlob(that: Blob): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformFile(that: File): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformEntity(that: Entity): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  transformOperation(that: Operation): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformCapability(that: Capability): ISubmodelElement | null {
    return that as ISubmodelElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): ISubmodelElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_SUBMODEL_ELEMENT_TRANSFORMER = new AsSubmodelElementTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link ISubmodelElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asSubmodelElement(that: Class): ISubmodelElement | null {
  return AS_SUBMODEL_ELEMENT_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isSubmodelElement(that: Class): that is ISubmodelElement {
  return asSubmodelElement(that) !== null;
}

/**
 * Try to cast an instance of the model to {@link IRelationshipElement}.
 */
class AsRelationshipElementTransformer extends AbstractTransformer<IRelationshipElement | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformRelationshipElement(that: RelationshipElement): IRelationshipElement | null {
    return that as IRelationshipElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(
    that: MultiLanguageProperty
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IRelationshipElement | null {
    return that as IRelationshipElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(that: BasicEventElement): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IRelationshipElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_RELATIONSHIP_ELEMENT_TRANSFORMER = new AsRelationshipElementTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IRelationshipElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asRelationshipElement(that: Class): IRelationshipElement | null {
  return AS_RELATIONSHIP_ELEMENT_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isRelationshipElement(that: Class): that is IRelationshipElement {
  return asRelationshipElement(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link SubmodelElementList}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asSubmodelElementList(that: Class): SubmodelElementList | null {
  return that instanceof SubmodelElementList ? <SubmodelElementList>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isSubmodelElementList(that: Class): that is SubmodelElementList {
  return that instanceof SubmodelElementList;
}

/**
 * Try to cast `that` instance to
 * the class {@link SubmodelElementCollection}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asSubmodelElementCollection(
  that: Class
): SubmodelElementCollection | null {
  return that instanceof SubmodelElementCollection
    ? <SubmodelElementCollection>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isSubmodelElementCollection(
  that: Class
): that is SubmodelElementCollection {
  return that instanceof SubmodelElementCollection;
}

/**
 * Try to cast an instance of the model to {@link IDataElement}.
 */
class AsDataElementTransformer extends AbstractTransformer<IDataElement | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(that: RelationshipElement): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformProperty(that: Property): IDataElement | null {
    return that as IDataElement;
  }

  transformMultiLanguageProperty(that: MultiLanguageProperty): IDataElement | null {
    return that as IDataElement;
  }

  transformRange(that: Range): IDataElement | null {
    return that as IDataElement;
  }

  transformReferenceElement(that: ReferenceElement): IDataElement | null {
    return that as IDataElement;
  }

  transformBlob(that: Blob): IDataElement | null {
    return that as IDataElement;
  }

  transformFile(that: File): IDataElement | null {
    return that as IDataElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(that: BasicEventElement): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IDataElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_DATA_ELEMENT_TRANSFORMER = new AsDataElementTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IDataElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asDataElement(that: Class): IDataElement | null {
  return AS_DATA_ELEMENT_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isDataElement(that: Class): that is IDataElement {
  return asDataElement(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link Property}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asProperty(that: Class): Property | null {
  return that instanceof Property ? <Property>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isProperty(that: Class): that is Property {
  return that instanceof Property;
}

/**
 * Try to cast `that` instance to
 * the class {@link MultiLanguageProperty}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asMultiLanguageProperty(that: Class): MultiLanguageProperty | null {
  return that instanceof MultiLanguageProperty ? <MultiLanguageProperty>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isMultiLanguageProperty(that: Class): that is MultiLanguageProperty {
  return that instanceof MultiLanguageProperty;
}

/**
 * Try to cast `that` instance to
 * the class {@link Range}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asRange(that: Class): Range | null {
  return that instanceof Range ? <Range>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isRange(that: Class): that is Range {
  return that instanceof Range;
}

/**
 * Try to cast `that` instance to
 * the class {@link ReferenceElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asReferenceElement(that: Class): ReferenceElement | null {
  return that instanceof ReferenceElement ? <ReferenceElement>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isReferenceElement(that: Class): that is ReferenceElement {
  return that instanceof ReferenceElement;
}

/**
 * Try to cast `that` instance to
 * the class {@link Blob}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asBlob(that: Class): Blob | null {
  return that instanceof Blob ? <Blob>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isBlob(that: Class): that is Blob {
  return that instanceof Blob;
}

/**
 * Try to cast `that` instance to
 * the class {@link File}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asFile(that: Class): File | null {
  return that instanceof File ? <File>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isFile(that: Class): that is File {
  return that instanceof File;
}

/**
 * Try to cast `that` instance to
 * the class {@link AnnotatedRelationshipElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asAnnotatedRelationshipElement(
  that: Class
): AnnotatedRelationshipElement | null {
  return that instanceof AnnotatedRelationshipElement
    ? <AnnotatedRelationshipElement>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isAnnotatedRelationshipElement(
  that: Class
): that is AnnotatedRelationshipElement {
  return that instanceof AnnotatedRelationshipElement;
}

/**
 * Try to cast `that` instance to
 * the class {@link Entity}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asEntity(that: Class): Entity | null {
  return that instanceof Entity ? <Entity>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isEntity(that: Class): that is Entity {
  return that instanceof Entity;
}

/**
 * Try to cast `that` instance to
 * the class {@link EventPayload}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asEventPayload(that: Class): EventPayload | null {
  return that instanceof EventPayload ? <EventPayload>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isEventPayload(that: Class): that is EventPayload {
  return that instanceof EventPayload;
}

/**
 * Try to cast an instance of the model to {@link IEventElement}.
 */
class AsEventElementTransformer extends AbstractTransformer<IEventElement | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(that: RelationshipElement): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(that: MultiLanguageProperty): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformBasicEventElement(that: BasicEventElement): IEventElement | null {
    return that as IEventElement;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(that: LangStringNameType): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(that: LangStringTextType): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IEventElement | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_EVENT_ELEMENT_TRANSFORMER = new AsEventElementTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IEventElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asEventElement(that: Class): IEventElement | null {
  return AS_EVENT_ELEMENT_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isEventElement(that: Class): that is IEventElement {
  return asEventElement(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link BasicEventElement}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asBasicEventElement(that: Class): BasicEventElement | null {
  return that instanceof BasicEventElement ? <BasicEventElement>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isBasicEventElement(that: Class): that is BasicEventElement {
  return that instanceof BasicEventElement;
}

/**
 * Try to cast `that` instance to
 * the class {@link Operation}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asOperation(that: Class): Operation | null {
  return that instanceof Operation ? <Operation>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isOperation(that: Class): that is Operation {
  return that instanceof Operation;
}

/**
 * Try to cast `that` instance to
 * the class {@link OperationVariable}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asOperationVariable(that: Class): OperationVariable | null {
  return that instanceof OperationVariable ? <OperationVariable>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isOperationVariable(that: Class): that is OperationVariable {
  return that instanceof OperationVariable;
}

/**
 * Try to cast `that` instance to
 * the class {@link Capability}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asCapability(that: Class): Capability | null {
  return that instanceof Capability ? <Capability>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isCapability(that: Class): that is Capability {
  return that instanceof Capability;
}

/**
 * Try to cast `that` instance to
 * the class {@link ConceptDescription}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asConceptDescription(that: Class): ConceptDescription | null {
  return that instanceof ConceptDescription ? <ConceptDescription>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isConceptDescription(that: Class): that is ConceptDescription {
  return that instanceof ConceptDescription;
}

/**
 * Try to cast `that` instance to
 * the class {@link Reference}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asReference(that: Class): Reference | null {
  return that instanceof Reference ? <Reference>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isReference(that: Class): that is Reference {
  return that instanceof Reference;
}

/**
 * Try to cast `that` instance to
 * the class {@link Key}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asKey(that: Class): Key | null {
  return that instanceof Key ? <Key>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isKey(that: Class): that is Key {
  return that instanceof Key;
}

/**
 * Try to cast an instance of the model to {@link IAbstractLangString}.
 */
class AsAbstractLangStringTransformer extends AbstractTransformer<IAbstractLangString | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(that: RelationshipElement): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(that: SubmodelElementList): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(
    that: MultiLanguageProperty
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(that: BasicEventElement): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(that: OperationVariable): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(that: ConceptDescription): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformLangStringNameType(that: LangStringNameType): IAbstractLangString | null {
    return that as IAbstractLangString;
  }

  transformLangStringTextType(that: LangStringTextType): IAbstractLangString | null {
    return that as IAbstractLangString;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(that: ValueReferencePair): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IAbstractLangString | null {
    return that as IAbstractLangString;
  }

  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IAbstractLangString | null {
    return that as IAbstractLangString;
  }

  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IAbstractLangString | null {
    return that as IAbstractLangString;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IAbstractLangString | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const AS_ABSTRACT_LANG_STRING_TRANSFORMER = new AsAbstractLangStringTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IAbstractLangString}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asAbstractLangString(that: Class): IAbstractLangString | null {
  return AS_ABSTRACT_LANG_STRING_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isAbstractLangString(that: Class): that is IAbstractLangString {
  return asAbstractLangString(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link LangStringNameType}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asLangStringNameType(that: Class): LangStringNameType | null {
  return that instanceof LangStringNameType ? <LangStringNameType>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isLangStringNameType(that: Class): that is LangStringNameType {
  return that instanceof LangStringNameType;
}

/**
 * Try to cast `that` instance to
 * the class {@link LangStringTextType}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asLangStringTextType(that: Class): LangStringTextType | null {
  return that instanceof LangStringTextType ? <LangStringTextType>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isLangStringTextType(that: Class): that is LangStringTextType {
  return that instanceof LangStringTextType;
}

/**
 * Try to cast `that` instance to
 * the class {@link Environment}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asEnvironment(that: Class): Environment | null {
  return that instanceof Environment ? <Environment>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isEnvironment(that: Class): that is Environment {
  return that instanceof Environment;
}

/**
 * Try to cast an instance of the model to {@link IDataSpecificationContent}.
 */
class AsDataSpecificationContentTransformer extends AbstractTransformer<IDataSpecificationContent | null> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtension(that: Extension): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformation(
    that: AdministrativeInformation
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifier(that: Qualifier): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShell(
    that: AssetAdministrationShell
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformation(that: AssetInformation): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResource(that: Resource): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetId(that: SpecificAssetId): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodel(that: Submodel): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElement(
    that: RelationshipElement
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementList(
    that: SubmodelElementList
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollection(
    that: SubmodelElementCollection
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformProperty(that: Property): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguageProperty(
    that: MultiLanguageProperty
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRange(that: Range): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElement(that: ReferenceElement): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlob(that: Blob): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFile(that: File): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElement(
    that: AnnotatedRelationshipElement
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntity(that: Entity): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayload(that: EventPayload): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElement(
    that: BasicEventElement
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperation(that: Operation): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariable(
    that: OperationVariable
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapability(that: Capability): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescription(
    that: ConceptDescription
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReference(that: Reference): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKey(that: Key): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameType(
    that: LangStringNameType
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextType(
    that: LangStringTextType
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironment(that: Environment): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecification(
    that: EmbeddedDataSpecification
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelType(that: LevelType): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePair(
    that: ValueReferencePair
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueList(that: ValueList): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360(
    that: LangStringPreferredNameTypeIec61360
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360(
    that: LangStringShortNameTypeIec61360
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360(
    that: LangStringDefinitionTypeIec61360
  ): IDataSpecificationContent | null {
    return null;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  transformDataSpecificationIec61360(
    that: DataSpecificationIec61360
  ): IDataSpecificationContent | null {
    return that as IDataSpecificationContent;
  }
}

const AS_DATA_SPECIFICATION_CONTENT_TRANSFORMER =
  new AsDataSpecificationContentTransformer();

/**
 * Try to cast `that` instance to
 * the interface {@link IDataSpecificationContent}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asDataSpecificationContent(
  that: Class
): IDataSpecificationContent | null {
  return AS_DATA_SPECIFICATION_CONTENT_TRANSFORMER.transform(that);
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isDataSpecificationContent(
  that: Class
): that is IDataSpecificationContent {
  return asDataSpecificationContent(that) !== null;
}

/**
 * Try to cast `that` instance to
 * the class {@link EmbeddedDataSpecification}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asEmbeddedDataSpecification(
  that: Class
): EmbeddedDataSpecification | null {
  return that instanceof EmbeddedDataSpecification
    ? <EmbeddedDataSpecification>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isEmbeddedDataSpecification(
  that: Class
): that is EmbeddedDataSpecification {
  return that instanceof EmbeddedDataSpecification;
}

/**
 * Try to cast `that` instance to
 * the class {@link LevelType}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asLevelType(that: Class): LevelType | null {
  return that instanceof LevelType ? <LevelType>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isLevelType(that: Class): that is LevelType {
  return that instanceof LevelType;
}

/**
 * Try to cast `that` instance to
 * the class {@link ValueReferencePair}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asValueReferencePair(that: Class): ValueReferencePair | null {
  return that instanceof ValueReferencePair ? <ValueReferencePair>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isValueReferencePair(that: Class): that is ValueReferencePair {
  return that instanceof ValueReferencePair;
}

/**
 * Try to cast `that` instance to
 * the class {@link ValueList}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asValueList(that: Class): ValueList | null {
  return that instanceof ValueList ? <ValueList>that : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isValueList(that: Class): that is ValueList {
  return that instanceof ValueList;
}

/**
 * Try to cast `that` instance to
 * the class {@link LangStringPreferredNameTypeIec61360}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asLangStringPreferredNameTypeIec61360(
  that: Class
): LangStringPreferredNameTypeIec61360 | null {
  return that instanceof LangStringPreferredNameTypeIec61360
    ? <LangStringPreferredNameTypeIec61360>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isLangStringPreferredNameTypeIec61360(
  that: Class
): that is LangStringPreferredNameTypeIec61360 {
  return that instanceof LangStringPreferredNameTypeIec61360;
}

/**
 * Try to cast `that` instance to
 * the class {@link LangStringShortNameTypeIec61360}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asLangStringShortNameTypeIec61360(
  that: Class
): LangStringShortNameTypeIec61360 | null {
  return that instanceof LangStringShortNameTypeIec61360
    ? <LangStringShortNameTypeIec61360>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isLangStringShortNameTypeIec61360(
  that: Class
): that is LangStringShortNameTypeIec61360 {
  return that instanceof LangStringShortNameTypeIec61360;
}

/**
 * Try to cast `that` instance to
 * the class {@link LangStringDefinitionTypeIec61360}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asLangStringDefinitionTypeIec61360(
  that: Class
): LangStringDefinitionTypeIec61360 | null {
  return that instanceof LangStringDefinitionTypeIec61360
    ? <LangStringDefinitionTypeIec61360>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isLangStringDefinitionTypeIec61360(
  that: Class
): that is LangStringDefinitionTypeIec61360 {
  return that instanceof LangStringDefinitionTypeIec61360;
}

/**
 * Try to cast `that` instance to
 * the class {@link DataSpecificationIec61360}.
 *
 * @param that - instance to be casted
 * @returns - casted `that` if cast successful, or `null`
 */
export function asDataSpecificationIec61360(
  that: Class
): DataSpecificationIec61360 | null {
  return that instanceof DataSpecificationIec61360
    ? <DataSpecificationIec61360>that
    : null;
}

/**
 * Check the type of `that` instance.
 *
 * @param that - instance to be type-checked
 * @returns `true` if the type check is successful
 */
export function isDataSpecificationIec61360(
  that: Class
): that is DataSpecificationIec61360 {
  return that instanceof DataSpecificationIec61360;
}

class TypeMatcher extends AbstractTransformerWithContext<Readonly<Class>, boolean> {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformExtensionWithContext(that: Extension, other: Class): boolean {
    return isExtension(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAdministrativeInformationWithContext(
    that: AdministrativeInformation,
    other: Class
  ): boolean {
    return isAdministrativeInformation(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformQualifierWithContext(that: Qualifier, other: Class): boolean {
    return isQualifier(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetAdministrationShellWithContext(
    that: AssetAdministrationShell,
    other: Class
  ): boolean {
    return isAssetAdministrationShell(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAssetInformationWithContext(that: AssetInformation, other: Class): boolean {
    return isAssetInformation(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformResourceWithContext(that: Resource, other: Class): boolean {
    return isResource(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSpecificAssetIdWithContext(that: SpecificAssetId, other: Class): boolean {
    return isSpecificAssetId(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelWithContext(that: Submodel, other: Class): boolean {
    return isSubmodel(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRelationshipElementWithContext(
    that: RelationshipElement,
    other: Class
  ): boolean {
    return isRelationshipElement(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementListWithContext(
    that: SubmodelElementList,
    other: Class
  ): boolean {
    return isSubmodelElementList(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformSubmodelElementCollectionWithContext(
    that: SubmodelElementCollection,
    other: Class
  ): boolean {
    return isSubmodelElementCollection(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformPropertyWithContext(that: Property, other: Class): boolean {
    return isProperty(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformMultiLanguagePropertyWithContext(
    that: MultiLanguageProperty,
    other: Class
  ): boolean {
    return isMultiLanguageProperty(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformRangeWithContext(that: Range, other: Class): boolean {
    return isRange(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceElementWithContext(that: ReferenceElement, other: Class): boolean {
    return isReferenceElement(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBlobWithContext(that: Blob, other: Class): boolean {
    return isBlob(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformFileWithContext(that: File, other: Class): boolean {
    return isFile(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformAnnotatedRelationshipElementWithContext(
    that: AnnotatedRelationshipElement,
    other: Class
  ): boolean {
    return isAnnotatedRelationshipElement(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEntityWithContext(that: Entity, other: Class): boolean {
    return isEntity(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEventPayloadWithContext(that: EventPayload, other: Class): boolean {
    return isEventPayload(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformBasicEventElementWithContext(
    that: BasicEventElement,
    other: Class
  ): boolean {
    return isBasicEventElement(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationWithContext(that: Operation, other: Class): boolean {
    return isOperation(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformOperationVariableWithContext(
    that: OperationVariable,
    other: Class
  ): boolean {
    return isOperationVariable(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformCapabilityWithContext(that: Capability, other: Class): boolean {
    return isCapability(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformConceptDescriptionWithContext(
    that: ConceptDescription,
    other: Class
  ): boolean {
    return isConceptDescription(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformReferenceWithContext(that: Reference, other: Class): boolean {
    return isReference(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformKeyWithContext(that: Key, other: Class): boolean {
    return isKey(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringNameTypeWithContext(
    that: LangStringNameType,
    other: Class
  ): boolean {
    return isLangStringNameType(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringTextTypeWithContext(
    that: LangStringTextType,
    other: Class
  ): boolean {
    return isLangStringTextType(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEnvironmentWithContext(that: Environment, other: Class): boolean {
    return isEnvironment(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformEmbeddedDataSpecificationWithContext(
    that: EmbeddedDataSpecification,
    other: Class
  ): boolean {
    return isEmbeddedDataSpecification(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLevelTypeWithContext(that: LevelType, other: Class): boolean {
    return isLevelType(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueReferencePairWithContext(
    that: ValueReferencePair,
    other: Class
  ): boolean {
    return isValueReferencePair(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformValueListWithContext(that: ValueList, other: Class): boolean {
    return isValueList(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringPreferredNameTypeIec61360WithContext(
    that: LangStringPreferredNameTypeIec61360,
    other: Class
  ): boolean {
    return isLangStringPreferredNameTypeIec61360(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringShortNameTypeIec61360WithContext(
    that: LangStringShortNameTypeIec61360,
    other: Class
  ): boolean {
    return isLangStringShortNameTypeIec61360(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformLangStringDefinitionTypeIec61360WithContext(
    that: LangStringDefinitionTypeIec61360,
    other: Class
  ): boolean {
    return isLangStringDefinitionTypeIec61360(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  transformDataSpecificationIec61360WithContext(
    that: DataSpecificationIec61360,
    other: Class
  ): boolean {
    return isDataSpecificationIec61360(other);
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}

const TYPE_MATCHER = new TypeMatcher();

/**
 * Check whether the type of `that` matches the type of `other` instance.
 *
 * @remarks
 * We check with `is*` function. Hence, if the class of `other` is a subclass of
 * the class of `that`, we confirm the match.
 *
 * @param that - standard instance
 * @param other - instance whose type is compared against `that`
 */
export function typesMatch<ClassT extends Class>(
  that: ClassT,
  other: Class
): other is ClassT {
  return TYPE_MATCHER.transformWithContext(that, other);
}

// This code has been automatically generated by aas-core-codegen.
// Do NOT edit or append.
