import { encodeBase64 } from 'util/Base64Util';
import { Submodel } from '../v3/aas_core_meta/types';

export class TemplateShellApi {
    basePathOwnApi: string;
    basePathCustoms: string;
    basePathDefaults: string;
    private readonly useAuthentication = window._env_.REACT_APP_USE_AUTHENTICATION_FEATURE_FLAG;

    constructor() {
        this.basePathOwnApi = '/api/Template';
        this.basePathCustoms = '/templates/custom';
        this.basePathDefaults = '/templates/default';
    }

    public async getDefaults(token: string): Promise<Submodel[]> {

        let headers = {
            Accept: 'application/json'
        };
        if (this.useAuthentication) {
            headers['Authorization'] = token;
        }

        const response = await fetch(this.basePathOwnApi + '/allDefaultSubmodels', {
            method: 'GET',
            headers
        });

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw response;
        }
    }

    public async getCustoms(token: string): Promise<Submodel[]> {

        let headers = {
            Accept: 'application/json'
        };
        if (this.useAuthentication) {
            headers['Authorization'] = token;
        }

        const response = await fetch(this.basePathOwnApi + '/allCustomSubmodels', {
            method: 'GET',
            headers
        });

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw response;
        }
    }

    public async getCustom(token: string, submodelIdShort: string): Promise<Submodel> {

        let headers = {
            Accept: 'application/json'
        };
        if (this.useAuthentication) {
            headers['Authorization'] = token;
        }

        const response = await fetch(this.basePathOwnApi + '/CustomSubmodel/' + encodeBase64(submodelIdShort), {
            method: 'GET',
            headers
        });

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw response;
        }
    }

    public async deleteCustomById(token: string, id: string): Promise<string | number> {
        
        let headers = {
            Accept: 'application/json'
        };
        if (this.useAuthentication) {
            headers['Authorization'] = token;
        }

        // We use the regular delete endpoint, which expects an idShort, but because of our backend interception, we saved the actual id in the idShort field earlier.
        // That's why this works.
        const response = await fetch(this.basePathCustoms + '/' + encodeBase64(id), {
            method: 'DELETE',
            headers
        });

        if (response.status >= 200 && response.status < 300) {
            return response.status;
        } else {
            throw response;
        }
    }
}
