import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';

export interface MenuListItemProps {
    icon?: React.ReactElement;
    to?: string;
    external?: boolean;
    label?: React.ReactElement | string;
    target?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export function MenuListItem(props: MenuListItemProps) {
    const content = (
        <>
            {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
            {props.label && <ListItemText data-testid="sidebar-button">{props.label}</ListItemText>}
        </>
    );

    return props.to ? (
        !props.external ? (
            <ListItemButton component={NavLink} to={props.to} target={props.target} onClick={props.onClick}>
                {content}
            </ListItemButton>
        ) : (
            <ListItemButton component={'a'} href={props.to} target={props.target} onClick={props.onClick}>
                {content}
            </ListItemButton>
        )
    ) : (
        <ListItemButton onClick={props.onClick}>{content}</ListItemButton>
    );
}
