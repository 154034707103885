import { SvgIcon, SvgIconProps } from '@mui/material';

export function PdfDocumentIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 11H6V4H13V9H18V11ZM7.18281 17.6079H8.38711C9.04947 17.6079 9.5914 17.0701 9.5914 16.4129V15.2178C9.5914 14.5605 9.04947 14.0227 8.38711 14.0227H5.97852V19.998H7.18281V17.6079ZM14.4086 15.2178V18.803C14.4086 19.4603 13.8667 19.998 13.2043 19.998H10.7957V14.0227H13.2043C13.8667 14.0227 14.4086 14.5605 14.4086 15.2178ZM18.0215 17.6079H16.8172V19.998H15.6129V14.0227H18.0215V15.2178H16.8172V16.4129H18.0215V17.6079ZM8.38713 15.2176H7.18283V16.4127H8.38713V15.2176ZM12 15.2176H13.2043V18.8028H12V15.2176Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
