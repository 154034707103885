import { Box, styled } from '@mui/material';
import { AppRoutes } from 'AppRoutes';
import { NotificationOutlet } from 'components/basics/NotificationOutlet';
import { useAuth } from 'hooks/UseAuth';
import { useIsTablet } from 'hooks/UseBreakpoints';
import { BrowserRouter } from 'react-router-dom';
import { Header } from './Header';

const StyledBox = styled(Box)(() => ({
    '&.sidebar-visible': {
        marginLeft: 275,
    },
    '&.topbar-visible': {
        // toolbar min-height is 56px
        marginTop: 56,
        '@media(min-width:600px)': {
            // toolbar min-height is 64px
            marginTop: 64,
        },
    },
}));

export function LayoutRoot() {
    const auth = useAuth();
    const isTablet = useIsTablet();

    return (
        <Box display="flex" height="100%" flexDirection="column">
            <Box display="flex" flex={1} flexDirection="column">
                <StyledBox
                    flex={1}
                    display="flex"
                    className={auth.isLoggedIn && !isTablet ? 'sidebar-visible' : 'topbar-visible'}
                >
                    <BrowserRouter>
                        <Header />
                        <AppRoutes />
                    </BrowserRouter>
                </StyledBox>
                <NotificationOutlet />
            </Box>
        </Box>
    );
}
