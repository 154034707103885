import { Route, Routes } from 'react-router-dom';
import { AASView } from 'components/views/AASView';
import { Home } from 'components/views/Home';
import { RepositorySettingsView } from 'components/views/settings/RepositorySettingsView';
import { TemplatesView } from 'components/views/TemplatesView';
import { Showcase } from 'components/views/Showcase';
import { TemplateEditView } from 'components/views/TemplateEditView';
import { PrivateRoute } from 'components/azureAuthentication/PrivateRoute';
import { AssetIdRedirect } from 'components/views/AssetIdRedirect';
import FeatureFlags from 'util/FeatureFlags';
import { CompareView } from 'components/views/compare-view/CompareView';
import { CompareAasContextProvider } from 'contexts/CompareAasContext';
import { AasListView } from 'components/views/aas-list/AasListView';

export function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="*" element={<Home />} />
                <Route path="/viewer/:base64AasId" element={<AASView />} />
                {FeatureFlags.aasListFeature && <Route path="/list" element={<AasListView />} />}
                <Route path="/asset/:assetIdParam" element={<AssetIdRedirect />} />
                {FeatureFlags.comparisonFeature && (
                    <Route
                        path="/compare"
                        element={
                            <CompareAasContextProvider>
                                <CompareView />
                            </CompareAasContextProvider>
                        }
                    />
                )}
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute>
                            <RepositorySettingsView />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/templates"
                    element={
                        <PrivateRoute>
                            <TemplatesView />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/templates/:id"
                    element={
                        <PrivateRoute>
                            <TemplateEditView />
                        </PrivateRoute>
                    }
                />
                {/* only for development, route can and should be removed if no longer needed */}
                <Route path="/showcase" element={<Showcase />} />
            </Routes>
        </>
    );
}
