import { Box, useTheme } from '@mui/material';
import { XitasoLogo } from '../components/basics/XitasoLogo';
import { useNavigate } from 'react-router-dom';

export function HeaderLogo() {
   const theme = useTheme();
   const navigate = useNavigate();

   const goToHome = () => {
        navigate('/');
    } 
    
   return (
       <Box data-testid="header-logo" onClick={goToHome} sx={{ height:'100%', cursor: 'pointer' }}>
           {theme?.productLogo?.logo ?
               <img height="100%" src={theme.productLogo.logo}/> :
               <XitasoLogo/>
           }
       </Box>
   );
}
