import { SyntheticEvent, useState } from 'react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view';
import { Entity, ISubmodelElement, KeyTypes } from 'api/v3/aas_core_meta/types';
import { SubmodelViewObject } from 'types/SubmodelViewObject';
import { GetKeyType } from 'util/KeyTypeUtil';
import { generateSubmodelViewObjectFromSubmodelElement } from 'util/SubmodelViewObjectUtil';
import { ApplicationTreeItem } from './ApplicationTreeItem';

type ApplicationComponentProps = {
    readonly entity: Entity;
};

export function ApplicationComponent(props: ApplicationComponentProps) {
    const { entity } = props;
    const [expandedTreeItems, setExpandedTreeItems] = useState<string[]>(['0']);
    const entityTree: SubmodelViewObject = generateSubmodelViewObjectFromSubmodelElement(entity, '0');

    const handleToggle = (_event: SyntheticEvent, nodeIds: string[]) => {
        setExpandedTreeItems(nodeIds);
    };

    const renderTree = (tree: SubmodelViewObject) => {
        const hasChildEntities =
            tree.children.filter((child) => child.data && GetKeyType(child.data) === KeyTypes.Entity).length > 0;
        const applicationUrl = tree.children.find((child) => child.name === 'ApplicationURL')?.propertyValue;
        return (
            <ApplicationTreeItem
                key={tree.id}
                nodeId={tree.id}
                label={tree.name}
                data={tree.data as ISubmodelElement}
                hasChildEntities={hasChildEntities}
                applicationUrl={applicationUrl}
            >
                {hasChildEntities ? tree.children.map((childTree) => renderTree(childTree)) : undefined}
            </ApplicationTreeItem>
        );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expandedTreeItems}
            onNodeToggle={handleToggle}
            disableSelection
        >
            {renderTree(entityTree)}
        </TreeView>
    );
}
