import { Entity, ISubmodelElement } from 'api/v3/aas_core_meta/types';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { SubmodelViewObject } from 'types/SubmodelViewObject';
import { generateSubmodelViewObjectFromSubmodelElement } from 'util/SubmodelViewObjectUtil';
import { TreeView } from '@mui/x-tree-view';
import { EntityTreeItem } from './EntityTreeItem';
import { SyntheticEvent, useState } from 'react';

type EntityComponentProps = {
    readonly entity: Entity;
};

export function EntityComponent(props: EntityComponentProps) {
    const { entity } = props;
    const [expandedTreeItems, setExpandedTreeItems] = useState<string[]>(['0']);
    const entityTree: SubmodelViewObject = generateSubmodelViewObjectFromSubmodelElement(entity, '0');

    const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
        setExpandedTreeItems(nodeIds);
    };

    const renderTree = (tree: SubmodelViewObject) => {
        return (
            <EntityTreeItem key={tree.id} nodeId={tree.id} label={tree.name} data={tree.data as ISubmodelElement}>
                {tree.children.length ? tree.children.map((childTree) => renderTree(childTree)) : undefined}
            </EntityTreeItem>
        );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expandedTreeItems}
            onNodeToggle={handleToggle}
            disableSelection
        >
            {renderTree(entityTree)}
        </TreeView>
    );
}
