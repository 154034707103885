import { Submodel } from 'api/v3/aas_core_meta/types';
import { ThemeSettings } from "types/ThemeSettings";

export class ConfigurationShellApi {
    basePath: string;
    private readonly useAuthentication = window._env_.REACT_APP_USE_AUTHENTICATION_FEATURE_FLAG;
    
    constructor(protected _basePath: string = '') {
        this.basePath = _basePath;
    }

    public async getIdGenerationSettings(bearerToken: string) : Promise<Submodel>{
        let headers = {
            Accept: 'application/json'
        }
        if (this.useAuthentication) {
            headers['Authorization'] = bearerToken;
        }

        const response = await fetch('/configuration/idGeneration', {
            method: 'GET',
            headers
        })

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw response;
        }
    }

    public async getThemeSettings(bearerToken: string):Promise<ThemeSettings>{
        let headers = {
            Accept: 'application/json'
        }
        if (this.useAuthentication) {
            headers['Authorization'] = bearerToken;
        }

        const response = await fetch('/configuration/theme/$value', {
            method: 'GET',
            headers
        })

        if (response.status >= 200 && response.status < 300) {
            const data = await response.json();
            return { primaryColor: data.PrimaryColor, secondaryColor: data.SecondaryColor, base64Logo: data.Base64Logo }
        } else {
            throw response;
        }
    }

    public async putSingleIdGenerationSetting(idShort: string, bearerToken: string, values: {
        prefix: string,
        dynamicPart: string
    }) {
        await this.putSingleSettingValue(`${idShort}.Prefix`, bearerToken, values.prefix, "idGeneration");
        await this.putSingleSettingValue(`${idShort}.DynamicPart`, bearerToken, values.dynamicPart, "idGeneration");
    }

    public async putSingleThemeSetting(bearerToken: string, values: ThemeSettings) {
        await this.putSingleSettingValue("PrimaryColor", bearerToken, values.primaryColor, "theme");
        await this.putSingleSettingValue("SecondaryColor", bearerToken, values.secondaryColor, "theme");
        await this.putSingleSettingValue("Base64Logo", bearerToken, values.base64Logo, "theme");
    }

    protected async putSingleSettingValue(path: string, bearerToken: string, value: string, settingsType:string): Promise<void | Response> {
        
        let headers = {
            'Content-Type': 'application/json',
        }
        if (this.useAuthentication) {
            headers['Authorization'] = bearerToken;
        }
        
        const response = await fetch(`/configuration/${settingsType}/submodel-elements/${path}/$value`, {
            method: 'PATCH',
            headers,
            body: '"' + value + '"'
        });

        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            throw response;
        }
    }
}
