export const NameplateSorting = [
    'SerialNumber',
    'ManufacturerProductRoot',
    'ManufacturerProductFamily',
    'ManufacturerProductType',
    'ManufacturerName',
    'YearOfConstruction',
    'Address',
    'ManufacturerProductDesignation',
    'OrderCode',
];
