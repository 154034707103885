import { useNavigate, useParams } from 'react-router-dom';
import { useApis } from '../azureAuthentication/ApiProvider';
import { useAsyncEffect } from '../../hooks/UseAsyncEffect';
import { encodeBase64 } from '../../util/Base64Util';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../i18n/localization';
import { useNotificationSpawner } from '../../hooks/UseNotificationSpawner';
import { Box, Typography } from '@mui/material';

import { showError } from 'util/ErrorHandlerUtil';
import { NotFoundError } from '../../errors/NotFoundError';
import { useState } from 'react';
import { CenteredLoadingSpinner } from '../basics/CenteredLoadingSpinner';

export function AssetIdRedirect() {
    const { lookupClient } = useApis();
    const navigate = useNavigate();
    const { assetIdParam } = useParams();
    const notificationSpawner = useNotificationSpawner();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useAsyncEffect(async () => {
        try {
            setIsLoading(true);
            await navigateToViewerOfAsset(assetIdParam);
        } catch (e) {
            setIsLoading(false);
            setIsError(true);
            showError(e, notificationSpawner);
        }
    }, []);

    async function navigateToViewerOfAsset(assetId: string | undefined): Promise<void> {
        const aasIds = await getAasIdsOfAsset(assetId);
        assertAnAasIdExists(aasIds);
        const targetUrl = determineViewerTargetUrl(aasIds);
        navigate(targetUrl, { replace: true });
    }

    async function getAasIdsOfAsset(assetId: string | undefined) {
        if (!assetId) {
            throw new NotFoundError();
        }
        const encodedAssetId = encodeBase64(assetId);
        return await lookupClient.getAasIds(encodedAssetId);
    }

    function assertAnAasIdExists(aasIds: string[]) {
        if (aasIds.length === 0) {
            throw new NotFoundError();
        }
    }

    function determineViewerTargetUrl(aasIds: string[]) {
        const encodedAasId = encodeBase64(aasIds[0]);
        return '/viewer/' + encodedAasId;
    }

    return (
        <Box sx={{ p: 2, m: 'auto' }}>
            {isLoading && <CenteredLoadingSpinner />}
            {isError && (
                <>
                    <Typography variant="h1" color="primary" align="center" sx={{ mt: 2 }}>
                        <FormattedMessage {...messages.mnestix.cannotLoadAasId.header} />
                    </Typography>
                    <Typography align="center" sx={{ mt: 2 }}>
                        <FormattedMessage
                            {...messages.mnestix.cannotLoadAasId.text}
                            values={{ assetId: assetIdParam }}
                        />
                    </Typography>
                </>
            )}
        </Box>
    );
}
