import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { AASOverviewCard } from '../../composits/aas-viewer/AASOverviewCard';
import { useNotificationSpawner } from 'hooks/UseNotificationSpawner';
import { FormattedMessage } from 'react-intl';
import { messages } from 'i18n/localization';
import { showError } from 'util/ErrorHandlerUtil';
import { CompareSubmodelsAccordion } from './CompareSubmodelsAccordion';
import { CompareAasAddDialog } from './add-aas/CompareAasAddDialog';
import { useCompareAasContext } from 'contexts/CompareAasContext';
import CloseIcon from '@mui/icons-material/Close';
import { AddAasToCompareCard } from './add-aas/AddAasToCompareCard';

export function CompareView() {
    const { compareAas, addSeveralAas, deleteAas } = useCompareAasContext();
    const [isLoadingAas, setIsLoadingAas] = useState(false);
    const notificationSpawner = useNotificationSpawner();
    const { state: { aasIds } = {} } = useLocation();
    const [addModalOpen, setAddModalOpen] = useState(false);

    useEffect(() => {
        async function _fetchAas() {
            try {
                setIsLoadingAas(true);
                if (aasIds) {
                    addSeveralAas(aasIds);
                }
            } catch (e) {
                showError(e, notificationSpawner);
            } finally {
                setIsLoadingAas(false);
            }
        }

        _fetchAas();
    }, []);

    const handleDetailsModalOpen = () => {
        setAddModalOpen(true);
    };

    const handleDetailsModalClose = () => {
        setAddModalOpen(false);
    };

    const handleDeleteAas = (aasId: string) => {
        deleteAas(aasId);
    };

    const pageStyles = {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        width: '100%',
        marginBottom: '50px',
        marginTop: '20px',
    };

    return (
        <Box sx={pageStyles}>
            <Box width="90%" maxWidth="1125px" margin="0 auto">
                <Typography variant="h2" textAlign="left" marginBottom="30px">
                    <FormattedMessage {...messages.mnestix.compare.title} />
                </Typography>
                {compareAas.length !== 0 || isLoadingAas ? (
                    <Box display="flex" flexDirection="column" gap="20px">
                        <Box display="flex" flexDirection="row" gap="20px">
                            {compareAas.map((aas, index) => (
                                <Box position="relative" key={index} width={1/3} data-testid={`compare-aas-${index}`}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => handleDeleteAas(aas.id)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                            zIndex: 5,
                                        }}
                                        data-testid={`delete-compare-aas-${index}`}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <AASOverviewCard
                                        key={index}
                                        aas={aas}
                                        productImage={aas.assetInformation.defaultThumbnail?.path}
                                        isLoading={isLoadingAas}
                                        hasImage={!!aas.assetInformation?.defaultThumbnail?.path}
                                        isAccordion={true}
                                        imageLinksToDetail={true}
                                    />
                                </Box>
                            ))}
                            {compareAas.length < 3 && <AddAasToCompareCard onClick={handleDetailsModalOpen} />}
                        </Box>
                        <Box width={compareAas.length / 3}>
                            <CompareSubmodelsAccordion />
                        </Box>
                    </Box>
                ) : (
                    <>
                        <AddAasToCompareCard onClick={handleDetailsModalOpen} isFirst={true} />
                    </>
                )}
            </Box>
            <CompareAasAddDialog open={addModalOpen} handleClose={handleDetailsModalClose} />
        </Box>
    );
}
