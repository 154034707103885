import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from 'i18n/localization';
import { SquaredIconButton } from 'components/basics/SquaredIconButton';
import { ArrowForward } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useApis } from 'components/azureAuthentication/ApiProvider';
import { encodeBase64 } from 'util/Base64Util';
import { repositoryClient } from 'api';
import { useCompareAasContext } from 'contexts/CompareAasContext';
import { showError } from 'util/ErrorHandlerUtil';
import { useNotificationSpawner } from 'hooks/UseNotificationSpawner';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';

type ManualAasAddInputProps = {
    onSubmit: () => void;
    focus: boolean;
};

export function ManualAasAddInput(props: ManualAasAddInputProps) {
    const { compareAas, addAas } = useCompareAasContext();
    const [val, setVal] = useState<string>('');
    const { lookupClient } = useApis();
    const notificationSpawner = useNotificationSpawner();
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
        inputRef?.current?.focus();
    }, [props.focus]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setVal(event.target.value);
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const aasIds = await lookupClient.getAasIds(encodeBase64(val));
            const encodedAasId = aasIds.length !== 0 ? encodeBase64(aasIds[0]) : encodeBase64(val);
            const response = await repositoryClient.getAssetAdministrationShellById(encodedAasId);
            const aasExists = compareAas.find((aas) => aas.id === response.id);
            if (aasExists) {
                setIsLoading(false);
                notificationSpawner.spawn({
                    message: intl.formatMessage(messages.mnestix.compare.aasAlreadyAdded),
                    severity: 'error',
                });
            } else {
                addAas(response);
                props.onSubmit();
            }
        } catch (e: unknown) {
            setIsLoading(false);
            showError(e, notificationSpawner);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        // Allow submit via enter
        if (event.key === 'Enter' && !!val) {
            handleSubmit();
        }
    };

    return (
        <Box display="flex" justifyContent="center" paddingY="20px" data-testid="manual-aas-add-input">
            <TextField
                id="manual-input"
                label={<FormattedMessage {...messages.mnestix.aasOrAssetId} />}
                data-testid="aasId-input"
                autoFocus={true}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                value={val}
                inputRef={inputRef}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton onClick={() => {setVal('')}}><CloseIcon/></IconButton>
                        </InputAdornment>
                }}
            />
            <SquaredIconButton
                sx={{ ml: 1 }}
                endIcon={<ArrowForward />}
                data-testid="aasId-submit-button"
                disabled={!val}
                onClick={handleSubmit}
                loading={isLoading}
            />
        </Box>
    );
}
