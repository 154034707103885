import { OpenInNew } from '@mui/icons-material';
import { Box, Button, Divider, Link, styled, Typography } from '@mui/material';
import { ManualAASViewerInput } from 'components/composits/aas-viewer/ManualAasViewerInput';
import { FormattedMessage } from 'react-intl';
import { messages } from 'i18n/localization';
import { useIsMobile } from 'hooks/UseBreakpoints';
import { ReactComponent as ScannerLogo } from 'assets/ScannerLogo.svg';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import FeatureFlags from 'util/FeatureFlags';
import { useState } from 'react';

export function Home() {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const theme = useTheme();
    const [inputFocus, setInputFocus] = useState<boolean>(true);

    const StyledLogo = styled(ScannerLogo)(() => ({
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block'
    }));
    
    const focusInput = () => {
        // The value gets toggled to trigger the useEffect in the child input component 'ManualAASViewerInput'.
        setInputFocus(!inputFocus);
    }

    return (
        <Box sx={{ p: 2, m: 'auto' }}>
            <Typography data-testid="welcome-text" variant="h1" color="primary" align="center" sx={{ mt: 2 }}>
                <FormattedMessage {...messages.mnestix.welcome} />
            </Typography>
            <Typography variant="h3" align="center">
                <FormattedMessage {...messages.mnestix.digitalTwinMadeEasy} />
            </Typography>
            <Divider sx={{ my: 2 }} />
            {!isMobile && (
                <Box>
                    <Typography color="text.secondary" textAlign="center">
                        <FormattedMessage {...messages.mnestix.scanAasId} />
                    </Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: -10, cursor: 'pointer' }} onClick={focusInput}>
                        <StyledLogo color={theme.palette.primary.main}/>
                    </Box>
                    <Typography color="text.secondary" textAlign="center" sx={{ mb: 2 }}>
                        <FormattedMessage {...messages.mnestix.orEnterManual} />:
                    </Typography>
                </Box>
            )}
            <ManualAASViewerInput focus={inputFocus}/>
            {!isMobile && FeatureFlags.aasListFeature && (
                <Box display="flex" flexDirection="column">
                    <Typography color="text.secondary" textAlign="center" sx={{ mb: 2, mt: 6 }}>
                        <FormattedMessage {...messages.mnestix.orSelectFromList} />:
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Button variant="contained" data-testid="aasList-Button-Home" onClick={() => navigate('/list')}>
                            <FormattedMessage {...messages.mnestix.goToListButton} />
                        </Button>
                    </Box>
                </Box>
            )}
            <Typography align="center" sx={{ mt: 4 }}>
                <FormattedMessage {...messages.mnestix.findOutMore} />:
            </Typography>
            <Typography align="center">
                <Link
                    href="https://mnestix.io"
                    target="_blank"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <span>mnestix.io</span>
                    <OpenInNew fontSize="small" />
                </Link>
            </Typography>
        </Box>
    );
}
