import { PropsWithChildren, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme as defaultTheme } from './theme';
import { deDE } from '@mui/material/locale';
import { CssBaseline, Theme, ThemeOptions } from '@mui/material';
import { configurationClient } from 'api';
import { useAsyncEffect } from 'hooks/UseAsyncEffect';
import { useAuth } from 'hooks/UseAuth';
import { DefaultThemeSettings } from '../components/views/settings/theme-settings/DefaultTheme';
export function CustomThemeProvider(props: PropsWithChildren<{ readonly skipStyleReset?: boolean }>) {
    const [muiTheme, setMuiTheme] = useState<Theme>(createTheme(defaultTheme, deDE));
    const [isLoading, setIstLoading] = useState(true);
    const auth = useAuth();
    const bearerToken = auth.getBearerToken();

    useAsyncEffect(async () => {
        setIstLoading(true);
        const theme: ThemeOptions = { ...defaultTheme };
        if (!theme.palette) return;

        try {
            const themeSettings = await configurationClient.getThemeSettings(bearerToken);
            
            theme.palette.primary = { main: themeSettings.primaryColor };
            theme.palette.secondary = { main: themeSettings.secondaryColor };
            theme.productLogo = { logo: themeSettings.base64Logo };
            setMuiTheme(createTheme(theme, deDE));
            
        } catch (e) {
            console.error('Cannot apply custom theme, using default theme.', e);
            
            theme.palette.primary = { main: DefaultThemeSettings.primaryColor };
            theme.palette.secondary = { main: DefaultThemeSettings.secondaryColor };
            theme.productLogo = { logo: DefaultThemeSettings.base64Logo };
            setMuiTheme(createTheme(theme, deDE));
            
        } finally {
            setIstLoading(false);
        }
    }, []); 
    
    return (
        <>
            {!isLoading && 
            <ThemeProvider theme={muiTheme}> 
                {!props.skipStyleReset && <CssBaseline />}
                {props.children}
            </ThemeProvider>} 
        </>
    );
}
