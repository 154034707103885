import { SvgIcon, SvgIconProps } from '@mui/material';

export function ShellIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33767 5C8.06972 5 7.81298 5.10753 7.62501 5.29848L4.28735 8.68913C4.10321 8.87619 4 9.12815 4 9.39064V18.9999H7.625V11H16.375V18.9999H20V9.39064C20 9.12815 19.8968 8.87619 19.7127 8.68913L16.375 5.29848C16.187 5.10753 15.9303 5 15.6623 5H8.33767ZM6.19971 3.89545C6.76362 3.32259 7.53383 3 8.33767 3H15.6623C16.4662 3 17.2364 3.32259 17.8003 3.89545L21.138 7.28609C21.6904 7.84728 22 8.60318 22 9.39064V18.9999C22 20.1045 21.1046 20.9999 20 20.9999H16.375C15.2704 20.9999 14.375 20.1045 14.375 18.9999V13H9.625V18.9999C9.625 20.1045 8.72957 20.9999 7.625 20.9999H4C2.89543 20.9999 2 20.1045 2 18.9999V9.39064C2 8.60318 2.30962 7.84728 2.86204 7.28609L6.19971 3.89545Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
