import React, { PropsWithChildren, createContext, useContext, useState } from 'react';
import { AssetAdministrationShell } from 'api/v3/aas_core_meta/types';

type CurrentAasContextType = [
    AssetAdministrationShell | null,
    React.Dispatch<React.SetStateAction<AssetAdministrationShell | null>>,
];

const CurrentAasContext = createContext<CurrentAasContextType | undefined>(undefined);

export const useCurrentAasContext = () => useContext(CurrentAasContext) as CurrentAasContextType;

export const CurrentAasContextProvider = (props: PropsWithChildren) => (
    <CurrentAasContext.Provider value={useState<AssetAdministrationShell | null>(null)}>
        {props.children}
    </CurrentAasContext.Provider>
);
