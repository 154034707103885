import { ReactComponent as Difference } from '../../assets/CompareAssets/Difference_decorator.svg';
import { styled } from '@mui/material';

const StyledSymbol = styled(Difference)(() => ({
    width: '24px',
    height: '24px',
    flexShrink: 0,
    verticalAlign: 'middle',
    marginRight: '8px',
}));

export function DifferenceSymbol() {
    return <StyledSymbol />;
}
