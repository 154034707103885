import MarkerIconA1 from '../../../../../../assets/LocationMarkers/LocationMarker_A1.svg';
import MarkerIconA2 from '../../../../../../assets/LocationMarkers/LocationMarker_A2.svg';
import MarkerIconA3 from '../../../../../../assets/LocationMarkers/LocationMarker_A3.svg';
import MarkerIconA4 from '../../../../../../assets/LocationMarkers/LocationMarker_A4.svg';
import MarkerIconB1 from '../../../../../../assets/LocationMarkers/LocationMarker_B1.svg';
import MarkerIconB2 from '../../../../../../assets/LocationMarkers/LocationMarker_B2.svg';
import MarkerIconB3 from '../../../../../../assets/LocationMarkers/LocationMarker_B3.svg';
import MarkerIconB4 from '../../../../../../assets/LocationMarkers/LocationMarker_B4.svg';
import MarkerIconB5 from '../../../../../../assets/LocationMarkers/LocationMarker_B5.svg';
import MarkerIconB6 from '../../../../../../assets/LocationMarkers/LocationMarker_B6.svg';
import MarkerIconB7 from '../../../../../../assets/LocationMarkers/LocationMarker_B7.svg';
import MarkerIconC1 from '../../../../../../assets/LocationMarkers/LocationMarker_C1.svg';
import MarkerIconC2 from '../../../../../../assets/LocationMarkers/LocationMarker_C2.svg';
import MarkerIconC3 from '../../../../../../assets/LocationMarkers/LocationMarker_C3.svg';
import MarkerIconC4 from '../../../../../../assets/LocationMarkers/LocationMarker_C4.svg';
import MarkerIconD from '../../../../../../assets/LocationMarkers/LocationMarker_D.svg';

export default {
    MarkerIconA1,
    MarkerIconA2,
    MarkerIconA3,
    MarkerIconA4,
    MarkerIconB1,
    MarkerIconB2,
    MarkerIconB3,
    MarkerIconB4,
    MarkerIconB5,
    MarkerIconB6,
    MarkerIconB7,
    MarkerIconC1,
    MarkerIconC2,
    MarkerIconC3,
    MarkerIconC4,
    MarkerIconD,
};
