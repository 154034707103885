import { Box, Button, Divider, FormControl, Paper, TextField, Typography } from '@mui/material';
import { CardHeading } from 'components/basics/CardHeading';
import { messages } from 'i18n/localization';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { ThemeSettings } from 'types/ThemeSettings';
import { DefaultThemeSettings } from './DefaultTheme';

type ThemeSettingCardProps = {
    readonly themeSettings: ThemeSettings;
    readonly handleChange: (values: ThemeSettings) => void;
};

export function ThemeSettingsCard(props: ThemeSettingCardProps) {
    const [formData, setFormData] = useState<ThemeSettings>(DefaultThemeSettings);
    const [colorError, setColorError] = useState<boolean>(false);

    useEffect(() => {
        setFormData(props.themeSettings);
    }, [props.themeSettings]);

    useEffect(() => {
        setColorError(!(isValidHexColor(formData.primaryColor) && isValidHexColor(formData.secondaryColor)));
    }, [formData]);

    const handleSubmit = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        props.handleChange(formData);
    };

    const handleChange = (e: React.BaseSyntheticEvent) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const isValidHexColor = (color: string) => {
        const regexp = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
        return regexp.test(color);
    };

    const resetToDefault = () => {
        setFormData(DefaultThemeSettings);
        props.handleChange(DefaultThemeSettings);
    };

    return (
        <Paper sx={{ p: 3, width: '100%' }}>
            <CardHeading
                title={<FormattedMessage {...messages.mnestix.themeSettings.heading} />}
                subtitle={<FormattedMessage {...messages.mnestix.themeSettings.description} />}
            />
            <Divider sx={{ mt: 2 }} />
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth variant="filled">
                    <Box display="flex" flexDirection="row" alignItems="baseline" sx={{ m: 2 }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                width: '160px',
                            }}
                        >
                            <FormattedMessage {...messages.mnestix.themeSettings.primaryColorLabel} />
                        </Typography>
                        <TextField
                            value={formData.primaryColor}
                            name="primaryColor"
                            onChange={handleChange}
                            label={<FormattedMessage {...messages.mnestix.themeSettings.primaryColorLabel} />}
                            variant="standard"
                            error={!isValidHexColor(formData.primaryColor)}
                            helperText={isValidHexColor(formData.primaryColor) ? '' : 'Not a valid hex color'}
                            required
                        />
                    </Box>
                </FormControl>
                <FormControl fullWidth variant="filled">
                    <Box display="flex" flexDirection="row" alignItems="baseline" sx={{ m: 2 }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                width: '160px',
                            }}
                        >
                            <FormattedMessage {...messages.mnestix.themeSettings.secondaryColorLabel} />
                        </Typography>
                        <TextField
                            value={formData.secondaryColor}
                            name="secondaryColor"
                            onChange={handleChange}
                            label={<FormattedMessage {...messages.mnestix.themeSettings.secondaryColorLabel} />}
                            variant="standard"
                            error={!isValidHexColor(formData.secondaryColor)}
                            helperText={isValidHexColor(formData.secondaryColor) ? '' : 'Not a valid hex color'}
                            required
                        />
                    </Box>
                </FormControl>
                <FormControl fullWidth variant="filled">
                    <Box display="flex" flexDirection="row" alignItems="baseline" sx={{ m: 2 }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                width: '160px',
                                minWidth: '160px',
                            }}
                        >
                            <FormattedMessage {...messages.mnestix.themeSettings.logo} />
                        </Typography>
                        <TextField
                            value={formData.base64Logo}
                            name="base64Logo"
                            onChange={handleChange}
                            helperText={<FormattedMessage {...messages.mnestix.themeSettings.logoHelperText} />}
                            label={<FormattedMessage {...messages.mnestix.themeSettings.logo} />}
                            variant="standard"
                            multiline
                            maxRows={5}
                            fullWidth
                            required
                        />
                    </Box>
                </FormControl>
                <Button sx={{ m: 2 }} variant="contained" disabled={colorError} type="submit">
                    <FormattedMessage {...messages.mnestix.themeSettings.submitButton} />
                </Button>
                <Button sx={{ m: 2 }} variant="outlined" onClick={resetToDefault}>
                    <FormattedMessage {...messages.mnestix.themeSettings.resetButton} />
                </Button>
            </form>
        </Paper>
    );
}
